import _ from 'lodash';
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from 'react-redux'
import firestore from "../../firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import moment from "moment";
import { Table, Button, Select, Tooltip, Dropdown, Form, Col, Row } from "antd";
import { DatePicker, Space } from "antd";
import { utils, writeFile } from "xlsx";
import {
    CheckOutlined,
    CloseOutlined,
    ExportOutlined,
    UpCircleOutlined,
    DownCircleOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import { collection, query, where, or, and, getDocs } from "firebase/firestore";
import reports from "../../firebaseService/reportsFunctions";
import toastHandler from "../../firebaseService/toastHandler";
import { constants } from "../../constants";
import sentryUtils from '../../utils/sentryUtils';
import { analytics } from '../../firebase';
import { logEvent } from '@firebase/analytics';

function CustomReport() {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [orgDataSource, setOrgDataSource] = useState([]);
    const [outerColumns, setOuterColumns] = useState([]);
    const [rowKey, setRowKey] = useState("id");
    const [projectIds, setProjectIds] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [showDate, setShowDate] = useState(true);
    const [showDateRange, setShowDateRange] = useState(false);
    const [userData, setUserData] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    let loggedInUser = useSelector(state => state.userSlice.user)
    let projectsList = [];
    let start, end;
    const { RangePicker } = DatePicker;

    const user = firebase.auth().currentUser;

    const [expandableObj, setExandableObj] = useState(null);
    const [groupByKey, setGroupByKey] = useState(null);
    const [projectfilteredInfo, setProjectFilteredInfo] = useState("");

    const setInitData = () => {
        setDataSource(orgDataSource);
        setOuterColumns(initOuterColumns);
        setExandableObj(null);
        setGroupByKey(null);
        setRowKey("id");
    };

    function sortBy(collection, property) {
        let result;
        for (let i = 0; i < collection.length; i++) {
            result = typeof collection[i][property] == "object" ?
                result = _.sortBy(orgDataSource, `${property}.value`) :
                result = _.sortBy(orgDataSource, `${property}`);
        }
        return result;
    }
    function groupBy(collection, property) {
        let sortedCollection = sortBy(collection, property);
        let result;
        for (let i = 0; i < sortedCollection.length; i++) {
            result = typeof sortedCollection[i][property] == "object" ?
                result = _.groupBy(sortedCollection, `${property}.value`) :
                result = _.groupBy(sortedCollection, `${property}`);
        }
        return result;
    }

    const groupTableRows = (selectedOption, filters) => {
        let opt = selectedOption;
        let groupByKeyArr = [];
        groupByKeyArr["logDate"] = "groupByDate";
        groupByKeyArr["approvalStatus"] = "groupByStatus";
        groupByKeyArr["project"] = "groupByProject";
        groupByKeyArr['userId'] = 'groupByUser'

        if (groupByKeyArr[selectedOption]) {

            opt =
                typeof selectedOption == "object"
                    ? selectedOption.value
                    : groupByKeyArr[selectedOption];
        } else {
            opt = selectedOption.value;
        }

        switch (opt) {
            case "clear":
                setInitData();
                break;
            case "groupByDate":
                setGroupByKey("logDate");
                changeDataFormat(groupBy(orgDataSource, "logDate"), "logDate", filters);
                break;
            case "groupByStatus":
                setGroupByKey("approvalStatus");
                changeDataFormat(
                    groupBy(orgDataSource, "approvalStatus"),
                    "approvalStatus",
                    filters
                );
                break;
            case "groupByProject":
                setGroupByKey("project");
                changeDataFormat(groupBy(orgDataSource, "project"), "project");
                break;
            case "groupByUser":
                setGroupByKey("userId");
                changeDataFormat(groupBy(orgDataSource, "userId"), "userId");
                break;
            default:
        }
    };

    const ExpandedRowRender = (props) => {
        const innerColumns = [
            {
                title: "Date",
                dataIndex: "logDate",
                key: "logDate",
            },
            {
                title: "User",
                dataIndex: "userName",
                key: "userName",
            },
            {
                title: "Team",
                dataIndex: "teamName",
                key: "teamName",
            },
            {
                title: "Project",
                dataIndex: "project",
                key: "project",
                render: (_, record) => record.project?.value || "-",
            },
            {
                title: "Task",
                dataIndex: "task",
                key: "task",
                render: (_, record) => record.task.value,
            },
            {
                title: "Subtask",
                dataIndex: "subtask",
                key: "subtask",
                render: (_, record) => record.subtask?.value,
            },
            {
                title: "Hour(s)",
                dataIndex: "logHours",
                key: "logHours",
                render: (_, record) => (
                    <>
                        {" "}
                        {record.logHours}{" "}
                        <span className="hrs">
                            {" "}
                            {record.logHours.split(":")[0] === "00" ? "Mins" : "Hrs"}
                        </span>{" "}
                    </>
                ),
            },
            {
                title: "Description",
                dataIndex: "description",
                key: "description",
                width: constants.width.descriptionClomnWidth,
                ellipsis: {
                    showTitle: false,
                },
                render: (e, record) => (
                    <Tooltip placement="leftBottom" title={record.description}>
                        {_.capitalize(_.trim(record.description)) || '-'}
                    </Tooltip>
                ),
            },
            {
                title: "Billable",
                dataIndex: "billable",
                key: "billable",
                align: "center",
                render: (billable) =>
                    billable ? (
                        <Tooltip placement="right" title={"Biilable"}>
                            {" "}
                            <span className="billable">
                                {" "}
                                <CheckOutlined />
                            </span>{" "}
                        </Tooltip>
                    ) : (
                        <Tooltip placement="right" title={"Non biilable"}>
                            {" "}
                            <span className="billable"> </span>
                            <span className="non-billable">
                                {" "}
                                <CloseOutlined />{" "}
                            </span>{" "}
                        </Tooltip>
                    ),
            },
            {
                title: "Approval Status",
                dataIndex: "approvalStatus",
                key: "approvalStatus",
                align: "center",
                render: (approvalStatus) => (
                    <span className={approvalStatus.toLowerCase()}>{approvalStatus}</span>
                ),
            },
        ];
        const dataRows = props.record.data.map((item, index) => ({
            ...item,
            key: item.id,
            date: item.logDate,
            hours: item.logHours,
            user: item.userName,
            billable: item.billable,
            approvalStatus: item.approvalStatus,
            logCost: "-",
            invoiceStatus: item.invoiceStatus,
            userName: item.userName,
            projectName: item.project?.value,
            task: item.task,
            subtask : item.subtask?.value,
        }));

        return (
            <div className="inner-table">
                <Table
                    rowKey={"key"}
                    columns={innerColumns}
                    showHeader={true}
                    dataSource={dataRows}
                    pagination={{ pageSize: 5 }}
                    size="small"
                    scroll={{ x: 1300 }}
                />
            </div>
        );
    };

    function changeDataFormat(groupedClientData, groupByCol, filters) {
        setProjectFilteredInfo(filters);
        let collectionArray = Object.values(groupedClientData) || [];
        const taskListNew = collectionArray.map((group, index) => {
            const projectNameString = _.map(group, 'project.value').join(', ');
            return {
                key: index.toString(),
                logDate: group[0]?.logDate,
                projectString: projectNameString,
                project: '',
                approvalStatus: group[0]?.approvalStatus,
                userName: group[0]?.userName,
                userId: group[0]?.userId,
                data: group,
            };
        });
        setDataSource(taskListNew);

        const outerColumnsNew = [
            {
                title: `Grouped by ${groupByCol
                    .replace("approvalStatus", "status")
                    .replace("logDate", "date")
                    .replace("userId", "user")}`,
                dataIndex: groupByCol,
                key: groupByCol,
                width: 500,
                render: (_, record) => {
                    let _totalMins = 0;
                
                    record["data"].forEach(item => {
                        let _Time = item["logHours"].split(':');
                        _totalMins += ((parseInt(_Time[0]) * 60) + parseInt(_Time[1]));
                    });
                
                    var _Hours = Math.floor(_totalMins / 60);
                    var _Minutes = _totalMins % 60;
                    let TotalHrs = String(_Hours).padStart(2, '0') + ':' + String(_Minutes).padStart(2, '0');
                
                    return typeof record["data"][0][groupByCol] === "object"
                        ? `${record["data"][0][groupByCol]["value"]}  (Logged ${_Hours} Hrs)`
                        : `${record[groupByCol === "userId" ? "userName" : groupByCol ]}  (Logged ${_Hours} Hrs)`;
                }
            },
            { title: "", dataIndex: "project" },
            { title: "" },
            { title: "" },
            { title: "" },
            { title: "" },
            { title: "" },
            { title: "" },
        ];
        setOuterColumns(outerColumnsNew);
        setExandableObj({
            expandedRowRender: (record, projectfilteredInfo) => (
                <ExpandedRowRender record={record} filters={projectfilteredInfo} />
            ),
            expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                    <UpCircleOutlined onClick={(e) => onExpand(record, e)} />
                ) : (
                    <DownCircleOutlined onClick={(e) => onExpand(record, e)} />
                ),
        });
        setRowKey("key");
    }

    const displayUpdates = () => {
        if (groupByKey != null) {
            changeDataFormat(
                groupBy(orgDataSource, groupByKey),
                groupByKey,
                projectfilteredInfo
            );
        } else {
            setDataSource(orgDataSource);
        }
    };

    useEffect(() => {
        displayUpdates();
    }, [groupByKey, orgDataSource]);

    useEffect(() =>{
        logEvent(analytics,"custom_report" , { "account_id": localStorage.getItem("accountId") });
    }, [])

    const getTeamList = async () => {
        if (user) {
            const teamsRef = collection(firestore, "teams");
            let q = query(
                teamsRef,
                and(
                    where("accountId", "==", localStorage.getItem("accountId")),
                    where("isDeleted", "==", false),
                    or(
                        where("projectManager.userId", "==", user.uid),
                        where("lead.userId", "==", user.uid),
                        where("teamCreator.userId", "==", user.uid),
                        where("teamMemberIds", "array-contains", user.uid)
                    )
                )
            );
            if (loggedInUser && loggedInUser.userRole !== undefined && loggedInUser.userRole === constants.usersRole.SUPER_ADMIN) {
                q = query(
                    teamsRef,
                    and(
                        where("accountId", "==", localStorage.getItem("accountId")),
                        where("isDeleted", "==", false),
                    )
                );
            }
            const querySnapshot1 = await getDocs(q);
            const getProjectList = querySnapshot1.docs.map((doc) => {
                const data = doc.data();
                data.docId = doc.id;
                return data.project;
            });
            setProjectList(getProjectList);
        }
    };

    const fetchUser = async () => {
        const response = await firestore
            .collection("users")
            .where("accountId", "==", localStorage.getItem("accountId"))
            .where("isAuthorized", "==", true)
            .get()
            .catch((error) => {
                toastHandler.error(error);
                sentryUtils.captureMondayException('fetchUser', error, 'customReport');
            });
        const userInfo = response.docs.map((doc) => {
            const info = {};
            const data = doc.data();
            info["key"] = data.userId;
            info["value"] = data.userName;
            return info;
        });
        setUserData(userInfo);
    };

    const getReports = async () => {
        setDataSource(orgDataSource);
        setOuterColumns(initOuterColumns);
        setGroupByKey(null);
        setExandableObj(null)
        setLoading(true);
        const data = {
            projectIds: projectIds,
            userIds: userIds,
            statusIds: statusOptions,
        };
        if (
            startDate &&
            endDate &&
            startDate != undefined &&
            endDate != undefined
        ) {
            data["startDate"] = startDate.toString();
            data["endDate"] = endDate.toString();
        }
        reports.customReportsData(data).then((result) => {
            const timeEntries = _.get(result, 'data') || [];
            if (timeEntries?.length == 0)
                toastHandler.info("No data, please modify filter");
            setDataSource(timeEntries);
            console.log('timeEntries', timeEntries)
            setOrgDataSource(timeEntries);
            setLoading(false);
        });
    };

    useEffect(() => {
        getTeamList();
    }, []);

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        updateDateRange({ value: "today", label: "Today" });
    }, []);

    const updateDateRange = (selectedDateOption) => {
        switch (selectedDateOption.value) {
            case "today":
                start = moment().startOf("day");
                end = moment();
                setStartDate(start);
                setEndDate(end);
                setShowDateRange(false);
                setShowDate(true);
                break;
            case "week":
                start = moment().startOf("week");
                end = moment();
                setStartDate(start);
                setEndDate(end);
                setShowDateRange(false);
                setShowDate(true);
                break;
            case "month":
                start = moment().startOf("month");
                end = moment();
                setStartDate(start);
                setEndDate(end);
                setShowDateRange(false);
                setShowDate(true);
                break;
            case "year":
                start = moment().startOf("year");
                end = moment();
                setStartDate(start);
                setEndDate(end);
                setShowDateRange(false);
                setShowDate(true);
                break;
            case "lastweek":
                start = moment().startOf("week").subtract(1, "week")
                end = moment().endOf("week").subtract(1, "week")
                setStartDate(start);
                setEndDate(end);
                setShowDateRange(false);
                setShowDate(true);
                break;
            case "lastmonth":
                start = moment().startOf("month").subtract(1, "month")
                end = moment().endOf("month").subtract(1, "month")
                setStartDate(start);
                setEndDate(end);
                setShowDateRange(false);
                setShowDate(true);
                break;
            case "custom":
                start = moment().startOf("month");
                end = moment();
                setStartDate(start);
                setEndDate(end);
                setShowDate(false);
                setShowDateRange(true);
                break;
            default:
                break;
        }
    };

    const updateCustomDateRange = (value) => {
        value[1] = moment(value[1]).endOf('day');
        setStartDate(value[0]);
        setEndDate(value[1]);
    };

    const updateUserIds = (userOptions) => {
        const userId = [];
        userOptions.forEach((user) => {
            userId.push(user.key);
        });
        setUserIds(userId);
    };

    const updateProjectIds = (projectOptions) => {
        var ids = [];
        projectOptions.forEach((project) => {
            ids.push(project.key);
        });
        setProjectIds(ids);
    };

    const updateStatusOptions = (statusOp) => {
        var status = [];
        statusOp.forEach((currentStatus) => {
            status.push(currentStatus.key);
        });
        setStatusOptions(status);
    };

    const taskList = [];
    for (let i = 0; i < orgDataSource.length; ++i) {
        let projectNameString = "";
        projectNameString += orgDataSource.map(function (element) {
            return element.project?.value;
        });

        taskList.push({
            key: i.toString(),
            date: orgDataSource[i][0]?.logDate,
            projectString: projectNameString,
            hours: "", //logDate
            data: orgDataSource[i],
        });
        projectsList.push(orgDataSource[i]?.project.value);
    }

    projectsList = projectsList
        .filter(function (v, i, self) {
            return i == self.indexOf(v);
        })
        .map(function (value) {
            return { text: value, value: value };
        });

    const initOuterColumns = [
        {
            title: "Date",
            dataIndex: "logDate",
            key: "logDate",
            width: 150,

        },
        {
            title: "User",
            dataIndex: "userName",
            key: "userName",
            width: 200,
            sorter: (a, b) => a.userName.localeCompare(b.userName)
        },
        {
            title: "Team",
            dataIndex: "teamName",
            width: 120,
            key: "teamName",
            sorter: (a, b) => a.teamName.localeCompare(b.teamName)

        },
        {
            title: "Project",
            dataIndex: "project",
            width: 200,
            key: "project",
            render: (_, record) => record.project?.value || "-",
            sorter: (a, b) => (a.project?.value || "").localeCompare(b.project?.value || ""),
        },
        {
            title: "Task",
            dataIndex: "task",
            width: 200,
            key: "task",
            render: (_, record) => record.task.value,
            sorter: (a, b) => (a.task?.value || "").localeCompare(b.task?.value || ""),
        },
        {
            title: "Subtask",
            dataIndex: "subtask",
            width: 200,
            key: "subtask",
            render: (_, record) => record.subtask?.value,
        },
        {
            title: "Hour(s)",
            dataIndex: "logHours",
            key: "logHours",
            width: 120,
            render: (_, record) => (
                <>
                    {" "}
                    {record.logHours}{" "}
                    <span className="hrs">
                        {" "}
                        {record.logHours.split(":")[0] === "00" ? "Mins" : "Hrs"}
                    </span>{" "}
                </>
            ),
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            width: constants.width.descriptionClomnWidth,
            ellipsis: {
                showTitle: false,
            },
            render: (e, record) => (
                <Tooltip placement="leftBottom" title={record.description}>
                    {_.capitalize(_.trim(record.description)) || '-'}
                </Tooltip>
            ),
        },
        {
            title: "Billable",
            dataIndex: "billable",
            key: "billable",
            align: "center",
            width: 120,
            render: (billable) =>
                billable ? (
                    <Tooltip placement="right" title={"Biilable"}>
                        {" "}
                        <span className="billable">
                            {" "}
                            <CheckOutlined />
                        </span>{" "}
                    </Tooltip>
                ) : (
                    <Tooltip placement="right" title={"Non biilable"}>
                        {" "}
                        <span className="billable"> </span>
                        <span className="non-billable">
                            {" "}
                            <CloseOutlined />{" "}
                        </span>{" "}
                    </Tooltip>
                ),
        },
        {
            title: "Status",
            dataIndex: "approvalStatus",
            key: "approvalStatus",
            align: "center",
            width: 120,
            render: (approvalStatus) => (
                <span className={approvalStatus.toLowerCase()}>{approvalStatus}</span>
            ),
        },
    ];

    useEffect(() => {
        setOuterColumns(initOuterColumns);
    }, [projectsList.length]);

    /* get state data and export to XLSX */
    const exportFile = useCallback(
        (fileType, key = null) => {
            let exportData = [];
            const groupedData = key ? groupBy(orgDataSource, key) : orgDataSource;
            if (key) {
                Object.entries(groupedData).map(([key, value]) => {
                    value.forEach((record, index) => {
                        exportData.push({
                            Index: index + 1,
                            Date: moment.utc(record.logDate).format(constants.dateTime.DATE),
                            Username: record.userName,
                            Team: record.teamName,
                            Project: record.project.value,
                            Task: record.task.value,
                            Subtask: record.subtask.value,
                            Hours: record.logHours,
                            Billable: record.billable ? "Billable" : "Non billable",
                            Status: record.approvalStatus,
                        });
                    });
                })
            } else {
                groupedData.forEach((record, index) => {
                    exportData.push({
                        Index: index + 1,
                        Date: moment.utc(record.logDate).format(constants.dateTime.DATE),
                        Username: record.userName,
                        Team: record.teamName,
                        Project: record.project.value,
                        Task: record.task.value,
                        Subtask: record.subtask?.value,
                        Hours: record.logHours,
                        Billable: record.billable ? "Billable" : "Non billable",
                        Status: record.approvalStatus,
                    });
                });

            }


            /* generate worksheet */
            const ws = utils.json_to_sheet(exportData);
            /* create workbook and append worksheet */
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");
            /* export to XLSX */
            writeFile(
                wb,
                `${"timesheet-" + moment().format("DD-MM-YYYY-hh-mm") + fileType}`
            );
        },
        [orgDataSource]
    );

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('pagination, filters, sorter, extra', pagination, filters, sorter, extra)
        const { currentDataSource } = extra;
        let sortedData = [...currentDataSource];
        setOrgDataSource(sortedData);
        setDataSource(sortedData)
        setPageSize(pagination.pageSize)
        // setProjectFilteredInfo(filters.project);
    };

    const items = [
        {
            key: "1",
            onClick: () => exportFile(".xlsx", groupByKey),
            label: <span> XLSX (MS Excel) </span>,
        },
        {
            key: "2",
            onClick: () => exportFile(".xls", groupByKey),
            label: <span> XLS (MS Excel 1997-2004) Compatible </span>,
        },
        {
            key: "3",
            onClick: () => exportFile(".csv", groupByKey),
            label: <span> CSV (Comma Seperated Value) </span>,
        },
    ];

    return (
        <>
            <div style={{ marginBottom: "20px" }}>
                <Row justify="end">
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={getReports}
                    >
                        Run Report
                    </Button>

                    <Dropdown
                        menu={{ items }}
                        disabled={!dataSource.length}
                        placement="bottomLeft"
                        arrow
                    >
                        <Button
                            type="primary"
                            className={dataSource.length > 0 ? "button-style":"button-disabled-style"}                            
                            style={{ marginRight: "10px" }}
                        >
                            <ExportOutlined /> Export As
                        </Button>
                    </Dropdown>

                    <Select
                        labelInValue
                        placeholder="Group by"
                        style={{ width: 160, marginRight: "10px" }}
                        disabled={!dataSource.length}
                        onChange={(selectedOption) =>
                            groupTableRows(selectedOption, projectfilteredInfo)
                        }
                        options={[
                            {
                                value: "clear",
                                label: "Clear",
                                onSelect: () => {
                                    console.log("Logs");
                                },
                            },
                            {
                                value: "groupByDate",
                                label: "Group By Date",
                            },
                            {
                                value: "groupByStatus",
                                label: "Group By Status",
                            },
                            {
                                value: "groupByProject",
                                label: "Group By Project",
                            },
                            {
                                value: "groupByUser", 
                                label: "Group By User" 
                            },
                        ]}
                    />
                </Row>
            </div>
            <div>
                <Row justify="space-around">
                    <Col span={11}>
                        <Form.Item
                            label={'Project'}
                            name="project"
                        >
                            <Select
                                labelInValue
                                placeholder="All"
                                mode="multiple"
                                onChange={(selectedOption) => updateProjectIds(selectedOption)}
                                options={projectList}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={11} >
                        <Form.Item
                            label={'User'}
                            name="user"
                        >
                            <Select
                                labelInValue
                                placeholder="All"
                                mode="multiple"
                                onChange={(selectedOption) => updateUserIds(selectedOption)}
                                options={userData}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="space-around">
                    <Col span={11}>
                        <Form.Item
                            label={'Status'}
                            name="status"
                        >
                            <Select
                                labelInValue
                                placeholder="All"
                                style={{ marginLeft: "2px" }}
                                mode="multiple"
                                onChange={(selectedOption) =>
                                    updateStatusOptions(selectedOption)
                                }
                                options={[
                                    { value: "Pending", label: "Pending" },
                                    { value: "Approved", label: "Approved" },
                                    { value: "Rejected", label: "Rejected" },
                                    { value: "Resubmitted", label: "Resubmitted" },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label={'Date'}
                            name="date"                            
                        >

                            <Select
                                labelInValue
                                defaultValue="today"
                                onChange={(selectedOption) => updateDateRange(selectedOption)}
                                options={[
                                    { value: "today", label: "Today" },
                                    { value: "week", label: "This Week" },
                                    { value: "month", label: "This Month" },
                                    { value: "year", label: "This Year" },
                                    { value: "lastweek", label: "Last Week" },
                                    { value: "lastmonth", label: "Last Month" },
                                    { value: "custom", label: "Custom" },
                                ]}
                            />
                            {showDate ? (
                                <Space direction="horizantal" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <label
                                        name="start"
                                    >
                                        {startDate
                                            ? startDate.format("MMM DD YYYY, hh:mm a   ")
                                            : null}{" "}
                                    </label>
                                    <label
                                        name="end"
                                        align="right"
                                    >
                                        {endDate
                                            ? endDate.format("MMM DD YYYY, hh:mm a  ")
                                            : null}
                                    </label>
                                </Space>
                            ) : null}

                            {showDateRange ? (
                                <RangePicker
                                    onChange={updateCustomDateRange}
                                    style={{ display: showDateRange, marginTop:'5px' }}
                                    defaultValue={[moment().startOf("month"), moment()]}
                                    disabledDate={(current) => current.isAfter(moment())}
                                    allowClear={false}
                                />
                            ) : null}

                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <div>
                {/* {dataSource.length > 0 ? ( */}
                <Table
                    loading={loading}
                    columns={outerColumns}
                    dataSource={dataSource}
                    expandable={expandableObj}
                    pagination={
                        { pageSize: pageSize,                          
                          showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total}`,
                         }}
                    rowKey={rowKey}
                    scroll={{ x: 1000, y: constants.tableHeight() }}
                    onChange={onChange}
                    sortDirections={["ascend", "descend"]}
                />
                {/* ) : null} */}
            </div>
        </>
    );
}
export default CustomReport;

import { Popover } from "antd";
import React from "react";

const CustomPopover = ({
  placement = "topLeft",
  content,
  title = "",
  data,
  trigger = "hover",
  open = false,
}) => {
  return (
    <div>
      <Popover
        placement={placement}
        content={<div style={{ color: "white" }}>{content}</div>}
        overlayClassName="custom-popover"
        title={title}
        trigger={trigger}
        // open={open}
      >
        <span>{data}</span>
      </Popover>
    </div>
  );
};

export default CustomPopover;

import _ from 'lodash';
import { ExportOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import React from "react";
import moment from "moment";
import { read, utils, writeFile } from "xlsx";
function ExportUserSummary(props) {

  let fileName = 'file';
  let headings = null;
  const data = _.get(props,'exportInfo.exportData') || [];
  if (props.exportInfo.from === 'userSummary'){
    fileName = "user-summary"
  }
  else if(props.exportInfo.from === 'projectSummary'){
    fileName = "project-summary"
  }
  else if(props.exportInfo.from === 'workload'){
    fileName = "workload"
  }

  let projectSummaryHeadings = [[...(_.get(props,'uniqueKeySet'))]] || [[]];
  if (props.exportInfo.from === 'userSummary' || props.exportInfo.from === 'projectSummary' || props.exportInfo.from === 'workload') {
    headings = projectSummaryHeadings
    fileName = fileName
  }
  const exportFile = (fileType) => {
    fileName = `${fileName + "-" + moment().format("DD-MM-YYYY-hh-mm") + fileType
      }`;
    let exportData = props.exportInfo.exportData;
    /* generate worksheet */
    const ws = utils.json_to_sheet(exportData);
    const sheetData = utils.sheet_add_aoa(ws, headings);
    sheetData['!cols'] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }]; // Adjust column width as needed

    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, sheetData, "Data");
    /* export to XLSX */
    writeFile(wb, fileName);
  };

  const items = [
    {
      key: "1",
      onClick: () => exportFile(".xlsx"),
      label: <span> XLSX (MS Excel) </span>,
    },
    {
      key: "2",
      onClick: () => exportFile(".xls"),
      label: <span> XLS (MS Excel 1997-2004) Compatible </span>,
    },
    {
      key: "3",
      onClick: () => exportFile(".csv"),
      label: <span> CSV (Comma Seperated Value) </span>,
    },
  ];
  return (
    <Dropdown menu={{ items }} disabled={data.length > 0 ? false : true} placement="bottomLeft" arrow>
      <Button
        type="primary"
        disabled={data.length > 0 ? false:true}
      >
        <ExportOutlined /> Export As
      </Button>
    </Dropdown>
  );
}

export default ExportUserSummary;

const colors = {
    // HOLIDAY_BG : "#f9b7c024", 
    // HOLIDAY_BG : "#F8CBAF",
    HOLIDAY_BG: "#fdf0e8",
    HOLIDAY_TEXT : "#003300",
    CARD_BORDER : "#E9E5E5",
    THEME_COLOR : '#181C33',    
    WEEKEND_BG : "#f5f5f5" ,
    RESOURCE_TOTAL_BG : "#D1FFD1",
    CHART_COLOR_YELLOW : "rgba(244,190,50)",
    CHART_COLOR_BLUE : "rgba(45,206,183)",
}

export default colors;


import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { collection, query, where, or, and, onSnapshot } from "firebase/firestore";
import { Space, Table, Popconfirm, Button, Tooltip, Popover, Select , Card, Alert, List} from 'antd';
import { EditOutlined, DeleteOutlined  } from "@ant-design/icons";
import { WarningOutlined } from '@ant-design/icons'
import "firebase/compat/auth";
import firestore from "../../firebase";
import toastHandler from "../../firebaseService/toastHandler";
import { constants } from "../../constants";
import users from "../../firebaseService/userFunctions";
import sentryUtils from "../../utils/sentryUtils";
// import { WarningAmberSharp } from "react-icons/md";
import EnquiryForm from "../Marketing/EnquiryForm";
// import accessDeniedImage from "../../assets/images/access_denied.png";
import "./users.css";

const UserList = () => {
    let loggedInUser = useSelector(state => state.userSlice.user)
    var usersList = [];
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    const [showPopconfirm, setShowPopconfirm] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isEnquiryOpen, setIsEnquiryOpen] = useState(false);
    // let imageSrc = accessDeniedImage;
    const handleSelect = (record, role) => {
        record.popConfirm = true
        record.newRole = role
        setShowPopconfirm(true);
        setSelectedRecord(record)
    };
    
    const handlePopconfirmConfirm = () => {
        updateUserRole(selectedRecord);
        setShowPopconfirm(false);
    };

    const handlePopconfirmCancel = () => {
        setShowPopconfirm(false);
    };

    const handleOk = () => {
        console.log("Enquiry Form Submitted");
        setIsEnquiryOpen(false); 
      };
    
    const handleCancel = () => {
    console.log("Enquiry Form Cancelled");
    setIsEnquiryOpen(false); 
    };

    useEffect(() => {

        try {
            const userRef = collection(firestore, "users");
            let q = query(
                userRef,
                where("accountId", "==", localStorage.getItem("accountId")),
                where("isAuthorized", "==", true),
            );
            
            const usersSnapshot = onSnapshot(
                q,
                (querySnapshot) => {
                    let users = [];
                    querySnapshot.forEach((doc) => {
                        const data = doc.data();
                        data.id = doc.id;
                        data.popConfirm = false
                        if (loggedInUser.userId === data.userId)
                            data.disabled = true
                        else
                            data.disabled = false
                        users.push(data);
                    });
                    setUserData(users);
                    setLoading(false)
                    console.log("inside usersSnapshot");
                },
                (error) => {
                    console.log("inside teamsSnapshot", error.message);
                    toastHandler.error("Error while fetching team list");
                    sentryUtils.captureFirestoreException('fetchUser', error, 'UsersIndex');
                }
            );
            setLoading(false);
            return () => {
                usersSnapshot();
                console.log("componentWillUnmount");
            };
        } catch (error) {
            setLoading(false);
            console.log(error);
            sentryUtils.captureGenericException('fetchUser', error, 'UsersIndex');
        }
    }, []);

    for (let i = 0; i < userData.length; i++) {
        usersList.push(userData[i]?.userName);
    }

    usersList = usersList.filter(function (v, i, self) { return i == self.indexOf(v); }).map(function (value) { return { text: value, value: value } });
    
    const updateUserRole = async(record) => {
        const updateUserData = {
            userId : record.userId,
            accountId : record.accountId,
            newRole : record.newRole,
        }
        users.updateUserRole(updateUserData);
    }

    const superAdmins = userData.filter(user => user.userRole === "SuperAdmin");
    console.log("superAdmins",superAdmins)
    if (loggedInUser?.userRole === "Admin") {
        if (superAdmins.length === 0) {
            return (
                <>
                <Card className="current-card">
                    <div className="label-container">
                        <WarningOutlined  className="warning-icon"/>
                        <span className="label" >
                                Action Required!
                        </span>
                    </div>
                    <p className="subtitle">SuperAdmin Role</p>
                    <ul>
                        <li className="livalue">Complete access to teams, timesheets, and approvals.</li>
                        <li className="livalue">View and manage the user list, including role updates.</li>
                        <li className="livalue">Access to summaries and dashboards for all teams.</li>
                    </ul>
                    <p className="value">Contact us to set up the "SuperAdmin" role for your organization.</p>
                    {/* <p>There is no Super Admin in your organization. Please contact us for further assistance.</p> */}
                    <div className="button-container">
                    <button  className="contact-button" onClick={() => setIsEnquiryOpen(true)}>
                            Contact Us &gt;
                    </button>
                    </div>
                </Card>
                {isEnquiryOpen && (
                    <EnquiryForm
                        show={isEnquiryOpen}
                        setShow={setIsEnquiryOpen}
                        open={isEnquiryOpen}
                        handleOk={handleOk}
                        handleCancel={handleCancel}
                        onCancel={() => setIsEnquiryOpen(false)}
                        isSuperAdminContact={true}
                    />
                )}
             </>
            );
        }
        
        return (
            <div>
                {/* <Alert
                    message="Restricted Permissions"
                    description="As an admin, you do not have the necessary permissions to proceed. Please contact the Super Admin for further assistance."
                    type="warning"
                    showIcon
                /> */}
                <Card className="current-card">
                    <div className="label-container">
                        <WarningOutlined  className="warning-icon"/>
                        <span className="label" >
                                Action Required!
                        </span>
                    </div>

                    <p className="livalue" >
                        As an admin, you do not have the necessary permissions to proceed. Please contact the Super Admin for further assistance.
                    </p>
                    <p className="value">
                        Contact below superadmin of your organisation
                    </p>
                    <List
                        bordered={false}
                        dataSource={superAdmins}
                        renderItem={(admin) => (
                            <List.Item style={{ border: 'none', padding: '4px 0' }}>
                                <span style={{ marginRight: '8px' }}>•</span>
                                {admin.userName} - {admin.email}
                            </List.Item>
                        )}
                    />
            </Card>
            </div>
        );
    }

    const columns = [
        {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
            // width: constants.width.teamNameColumnWidth - 30,
            ellipsis: true,
            fixed: 'left',
            sorter: (a, b) => a?.userName.localeCompare(b?.userName),
            filters: usersList,
            onFilter: (value, record) => ((record.userName.indexOf(value) > -1)),
            filterSearch: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            // width: constants.width.smallColumnWidth - 30,
            ellipsis: true,
            fixed: 'left',
            sorter: (a, b) => a?.email.localeCompare(b?.email),
        },
        {
            title: 'User Role',
            key: 'userRole',
            // width: constants.width.teamNameColumnWidth,
            dataIndex: 'userRole',
            render: (_, record) =>(
                <div>
                    <Popconfirm
                            title="Are you sure to update the role?"
                            okText="Yes"
                            cancelText="No"
                            open={record.popConfirm && showPopconfirm}
                            onConfirm={handlePopconfirmConfirm}
                            onCancel={handlePopconfirmCancel}
                    >
                    <Select
                        mode="single"
                        allowClear={false}
                        style={{ width: "150px" }}
                        disabled={record.disabled}
                        placeholder="Please select"
                        optionLabelProp="label"
                        options={[
                            { value: constants.usersRole.ADMIN, label: 'Admin' },
                            { value: 'Member', label: 'Member' },
                            { value: constants.usersRole.SUPER_ADMIN, label: 'Super Admin' }]}
                        value = {record.userRole}
                        onSelect={(newRole) => {
                            if (newRole !== record.userRole) {
                                handleSelect(record, newRole)
                            } else {
                            // Handle the case where the selected value is the same as the current role
                            console.log('User role already the same');
                            }
                        }}
                    />
                    </Popconfirm>
                    
                </div>
            ),
        },
    ];
    
    return (
        <div class="card mb-4">
            
            <Table style={{ marginRight: '10px' }}
                loading={loading}
                columns={columns}
                dataSource={userData}
                size={'small'}
                rowKey={'createdDate'}
            />
            
        </div>
    );
}

export default UserList;
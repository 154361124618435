import React, { useState, useEffect } from "react";
import {Button, Alert} from 'antd';
import enquiry from "../../firebaseService/enquiryFunctions";
import "../../App.css";
import "firebase/compat/auth";
import {
    Form,
    Input, 
    Select,
    Modal,
    Image,
    Typography,
  } from "antd";

import EnquiryIcon from "../../assets/images/enquiry_icon.png";
const { Title, Paragraph } = Typography;
export const EnquiryForm = (props) => {  
    const {open, handleOk ,handleCancel,isSuperAdminContact} = props;
    const [form] = Form.useForm();
    const { TextArea } = Input;    

    
    const closeModal = () => {
        handleCancel(false);
      };

      
    const onFinish = (values) => {    
        
        let enquiryData = {
          // name: values.name,
          email: values.email,
          // phone: values.phone,
          // designation: values.designation,
          // companyName: values.company_name,
          enquiryType: values.enquiryType,
          message: values.message,
        };
        console.log("enquiryData", enquiryData);
        enquiry.addEnquiry(enquiryData);
      };
    
    useEffect(() => {
        // Prefill the form if opened for Super Admin Contact
        if (isSuperAdminContact) {
            form.setFieldsValue({
                enquiryType: "Super Admin Contact",
            });
        }
    }, [isSuperAdminContact, form]);

    const modalTitle = (
    <div style={{ textAlign: "center", marginBottom: 0, paddingBottom: 0 }}>
        <Image
        src={EnquiryIcon}
        preview={false}
        style={{ marginBottom: "15px" }}
        />
        <Title
        style={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "14px",
            marginBottom: "0px",
            paddingBottom: 0,
        }}
        >
        Quick Enquiry
        </Title>
        <Paragraph
        style={{
            textAlign: "center",
            fontSize: "12px",
            marginTop: "5px",
            marginBottom: "-10px",
            color: "gray",
        }}
        >
        Get in touch if you would like a demo, premium support or customizations
        </Paragraph>
    </div>
    );
  
  return (
    <div>
    <Modal
        open={open}
        width={400}
        transitionName=""
        maskTransitionName=""
        title={modalTitle}
        okText="Send"
        cancelText="Cancel"
        onCancel={closeModal}
        footer={[
          <Button
            key="approve"
            type="primary"
            className="button-style"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  onFinish(values);
                  closeModal();
                })
                .catch((info) => {
                  console.log("Validate Failed:", info);
                });
            }}
          >
            Send
          </Button>,
        ]}
      >
      
    <Form
          form={form}
          layout="vertical"
          initialValues={{ modifier: "public" }}
          onFinish={onFinish}
        >         
            
          {/* <Space wrap>
            <Form.Item
              name="name"
              label="Name"
              style={{ width: 220 }}
              rules={[
                { required: true, message: "Please enter your name here." },
              ]}
            >
              <Input placeholder="Enter your name" />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              style={{ width: 220 }}
              rules={[
                {
                  type: "email",
                  message: "Please enter valid email.",
                },
                {
                  required: true,
                  message: "Please enter your email.",
                },
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
          </Space>

          <Space wrap>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[{ validator: validatePhoneNumber }, { required: false }]}
              style={{ width: 220 }}
            >
              <Input placeholder="Enter your phone" />
            </Form.Item>

            <Form.Item
              name="designation"
              label="Designation"
              style={{ width: 220 }}
            >
              <Input placeholder="Enter your designation" />
            </Form.Item>
          </Space>

          <Form.Item
            name="company_name"
            label="Company Name"
            rules={[
              {
                required: true,
                message: "Please enter your company name here.",
              },
            ]}
          >
            <Input placeholder="Enter your designation" />
          </Form.Item> */}
          {!isSuperAdminContact &&(
          <Form.Item
            name="enquiryType"
            style={{ fontSize: '12px' }}
            label={<span style={{fontSize: '12px' }}>How can we help you?</span>} 
            rules={[
              { required: true, message: 'Please select an option!' },
            ]}
            >
            <Select style={{ width: 350 }}
              placeholder = "--Please Select--"
              options={[
                { value: "Schedule a Demo", label: "Schedule a Demo" },
                { value: "Customization Requests", label: "Customization Requests"},
                { value: "Feedback and Suggestions", label: "Feedback and Suggestions" },
                { value: "Feature Request", label: "Feature Request" },
                { value: "Technical Support", label: "Technical Support" },
                { value: "Training", label: "Training" },
                { value: "Documentation", label: "Documentation" },
                { value: "Bug Report", label: "Bug Report" },
                { value: "Other", label: "Other"}

              ]}
            />
          </Form.Item>
          )}
          {isSuperAdminContact && ( // Hidden field with prefilled value
              <Form.Item
                  name="enquiryType"
                  hidden
              >
                  <Input />
              </Form.Item>
          )}

          <Form.Item
            name="email"
            label={<span style={{fontSize: '12px' }}>Email</span>}
            style={{ marginTop: "15px" }}
            rules={[
              {
                type: "email",
                message: "Please enter valid email.",
              },
              {
                required: true,
                message: "Please enter your email.",
              },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>         
          <Form.Item
            name="message"
            label={<span style={{ fontSize: '12px' }}>Message</span>}
            rows={4}
            style={{ marginBottom: "-10px" }}            
          >
            <TextArea placeholder="Enter your message..." />
          </Form.Item>
        </Form>
        </Modal>   
    </div>
  );
};
export default EnquiryForm;
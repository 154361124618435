import React, { useState, useEffect } from "react";
import { DatePicker, Select, Table, Spin, Button, Card } from "antd";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import mondaySdk from "monday-sdk-js";
import firebase from "firebase/compat/app";
import MondayConfig from "../../configs/MondayConfig";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logEvent } from "@firebase/analytics";
import { analytics, firestore } from "../../firebase";
import {
  collection,
  query,
  where,
  or,
  and,
  onSnapshot,
} from "firebase/firestore";
import toastHandler from "../../firebaseService/toastHandler";
import sentryUtils from "../../utils/sentryUtils";
import _ from "lodash";
import CustomPopover from "../../common/CustomPopover";
import imageSrc from "../../assets/images/subscription.png";
import reports from "../../firebaseService/reportsFunctions";
import colors from "../../constants/color";
import moment from "moment";

const { Option } = Select;
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Analysis = () => {
  const user = firebase.auth().currentUser;
  const monday = mondaySdk();
  monday.setApiVersion(MondayConfig.MONDAY_API_VERSION);
  const location = useLocation();
  const loggedInUser = useSelector((state) => state.userSlice.user);
  const navigate = useNavigate();
  const tablePageSize = 10;

  if (loggedInUser && !loggedInUser.hasTeam) {
    navigate("/master/AllSetting");
  }

  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Estimated Hours",
        backgroundColor: colors.CHART_COLOR_BLUE,
        data: [],
      },
      {
        label: "Hours Spent",
        backgroundColor: colors.CHART_COLOR_YELLOW,
        data: [],
      },
    ],
  });
  const [tableData, setTableData] = useState([]);
  const [firstTimeLogin, setFirstTimeLogin] = useState(false);
  const [listeners, setListeners] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [filteredChartData, setFilteredChartData] = useState(chartData);
  const [hasBoardAccess, setHasBoardAccess] = useState(true);
  const [syncBtnLoading, setSyncBtnLoading] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const [updatedAt,setUpdatedAt] = useState(null);

  const syncResourceAnalysis = () => {
    setLoading(true);
    setSyncBtnLoading(true);
    reports.syncResourceAnalysis().then((result) => {
      setSyncBtnLoading(false);
      setLoading(false);
    });
  };

  const formatHoursToHHMM = (hours) => {
    const wholeHours = Math.floor(hours); // Extract the integer part (hours)
    const minutes = Math.round((hours - wholeHours) * 60); // Convert fractional part to minutes
    return `${wholeHours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`; // Format as hh:mm
  };

  useEffect( () =>{
    if (updatedAt || updatedAt===undefined){
      syncResourceAnalysis();
    }
  },[updatedAt])

  // Fetch teams data from Firestore
  useEffect(() => {
    if (location && location.state && location.state.firstTimeLogin) {
      setFirstTimeLogin(true);
      logEvent(analytics, "team_first_time_login", {
        account_id: localStorage.getItem("accountId"),
      });
    }

    const fetchTeams = async () => {
      setLoading(true);
      try {
        const teamRef = collection(firestore, "projectsAnalysis");
        let q = query(
          teamRef,
          and(
            where("accountId", "==", localStorage.getItem("accountId")),
            where("deleted", "==", false)
          )
        );
        const teamSnapshot = onSnapshot(q, (querySnapshot) => {
          if (querySnapshot.empty) {
            setUpdatedAt(undefined)
            // syncResourceAnalysis()
            setHasBoardAccess(false);
            setLoading(false);
            // return;
          } else {
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              setAllProjects(data.projectsData);
              setHasBoardAccess(true);
              // setLoading(false);
              const updatedAt = data.updatedAt;
              if ((updatedAt && moment().diff(moment(updatedAt.toDate()), 'minutes') >=30)|| updatedAt===undefined) {
                // syncResourceAnalysis();
                setUpdatedAt(updatedAt);
                console.log("data sync")
              }
              setLoading(false);
            });
          }
        });

        setListeners((prev) => [...prev, teamSnapshot]);
      } catch (error) {
        setLoading(false);
        console.log(error);
        sentryUtils.captureGenericException("TeamList", error, "TeamList");
      }
    };

    fetchTeams();
    return () => {
      detachListeners();
    };
  }, [user, firstTimeLogin, loggedInUser, loggedInUser.userRole, location]);

  const tableColumns = [
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      align: "left",
      onCell: () => ({
        style: {
          paddingLeft: "16px", // Adjust this value as needed
        },
      }),
    },
    {
      title: "Project Manager",
      dataIndex: "projectManager",
      key: "projectManager",
      align: "left",
    },
    {
      title: "Estimated Timeline",
      dataIndex: "estimatedTimeline",
      key: "estimatedTimeline",
      align: "left",
    },
    {
      title: "Estimated Hours",
      dataIndex: "estimatedHours",
      key: "estimatedHours",
      align: "left",
    },
    {
      title: "Actual Timeline",
      dataIndex: "actualTimeline",
      key: "actualTimeline",
      align: "left",
    },
    {
      title: "Hours Spent",
      dataIndex: "actualHours",
      key: "actualHours",
      render: (text, record) => (
        <CustomPopover
          placement="topLeft"
          // trigger="click"
          content={
            <div style={{ color: "white" }}>
              <div>Billable: {record.billableHours}</div>
              <div>Non-Billable: {record.nonBillableHours}</div>
            </div>
          }
          data={text}
        />
      ),
      align: "left",
    },
  ];

  // Cleanup listeners on unmount
  useEffect(() => {
    return () => {
      detachListeners();
    };
  }, []);

  function detachListeners() {
    listeners.forEach((listener) => listener());
    setListeners([]);
  }

  const onChange = (selectedOptions) => {
    const selectedKeys = selectedOptions.map((option) => option.value);
    setSelectedProjects(selectedKeys);
    // console.log("Selected projects:", selectedKeys);
  };

  useEffect(() => {
    logEvent(analytics, "team_list", {
      account_id: localStorage.getItem("accountId"),
    });
  }, []);

  useEffect(() => {
    if (!allProjects || allProjects.length === 0) return;

    // Arrays for chart data
    const chartLabels = [];
    const estimatedHours = [];
    const actualHours = [];

    // Map all projects for table data
    const formattedTableData = allProjects.map((project) => ({
      key: project.projectKey,
      projectName: project.projectName,
      projectManager: project.projectManager?.userName || "-",
      estimatedTimeline: project.estimatedTimeline || "-",
      estimatedHours: project.estimatedFormattedHours || "00:00",
      actualTimeline: project.actualTimeline || "-",
      actualHours: project.actualFormattedHours || "00:00",
      billableHours: project.billableHours || "00:00",
      nonBillableHours: project.nonBillableHours || "00:00",
    }));

    // Filter projects for chart data
    allProjects
      .filter(
        (project) =>
          project.totalEstimatedHours !== 0 || project.totalActualHours !== 0
      )
      .forEach((project) => {
        chartLabels.push(project.projectName);
        estimatedHours.push(project.totalEstimatedHours || 0);
        actualHours.push(project.totalActualHours || 0);
      });

    // Set chart data with filtered projects
    setChartData({
      labels: chartLabels,
      datasets: [
        {
          label: "Estimated Hours",
          backgroundColor: colors.CHART_COLOR_BLUE,
          data: estimatedHours,
        },
        {
          label: "Hours Spent",
          backgroundColor: colors.CHART_COLOR_YELLOW,
          data: actualHours,
        },
      ],
    });

    // Set table data with all projects
    setTableData(formattedTableData);
  }, [allProjects]);

  useEffect(() => {
    if (selectedProjects.length === 0) {
      // Show all projects if no projects are selected
      setFilteredTableData(tableData);
      setFilteredChartData(chartData);
      return;
    }

    // Filter the table data based on selected projects
    const filteredData = tableData.filter((data) =>
      selectedProjects.includes(data.key)
    );

    // Extract data for the chart
    const filteredLabels = filteredData.map((data) => data.projectName);
    const filteredEstimatedHours = filteredData.map((data) =>
      parseFloat(data.estimatedHours)
    );
    const filteredActualHours = filteredData.map((data) =>
      parseFloat(data.actualHours)
    );

    setFilteredTableData(filteredData);
    setFilteredChartData({
      labels: filteredLabels,
      datasets: [
        {
          label: "Estimated Hours",
          backgroundColor: colors.CHART_COLOR_BLUE,
          data: filteredEstimatedHours,
        },
        {
          label: "Hours Spent",
          backgroundColor: colors.CHART_COLOR_YELLOW,
          data: filteredActualHours,
        },
      ],
    });
  }, [selectedProjects, tableData, chartData]);

  return (
    <>
      {!hasBoardAccess ? (
        <div className="no-access-modal">
          <img
            src={imageSrc}
            alt="Description"
            className="no-access-text-image"
            height={150}
            width={150}
          />
          <hr className="form-line form-line-hidden" />
          <p className="form-paragraph form-paragraph-title">
            Either create a team or ask admin to include you in a team!
          </p>
          <hr className="form-line form-line-hidden" />
          <p className="form-paragraph form-paragraph-text"></p>
        </div>
      ) : (
        <div id="container1">
          <div id="container2">
            <div className="analysis">
              <div className="analysis-header">Estimated vs. Hours Spent</div>
              <Button
                onClick={syncResourceAnalysis}
                loading={syncBtnLoading}
                disabled={syncBtnLoading}
              >
                <i class="fas fa-sync"></i>
                &nbsp; Sync
              </Button>
            </div>

            {/* Chart */}
            {loading ? (
              <div className="spin-div" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'70vh'}}>
                <Spin size="large" />
              </div>
            ) : (
              <>
              <div className="" style={{ marginBottom: 0, paddingBottom: 0 }}>
                <div className="">
                  <Card
                    className="analysis-card"
                    title={<span style={{ fontWeight: 600 }}>Timeline</span>}
                    extra={
                      <div className="analysis-select">
                        <span>Project: </span>
                        <Select
                          labelInValue
                          placeholder="All projects"
                          showArrow
                          mode="multiple"
                          onChange={onChange}
                          allowClear
                          maxTagCount={1}
                          optionFilterProp="children" // Enables search by the name in the options
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {tableData.map((val) => (
                            <Option value={val.key}>{val.projectName}</Option>
                          ))}
                        </Select>
                      </div>
                    }
                  >
                    <Bar
                      // style={containerBodyStyle}
                      data={{
                        ...filteredChartData,
                        datasets: filteredChartData.datasets.map((dataset) => ({
                          ...dataset,
                          maxBarThickness: 50,
                        })),
                      }}
                      options={{
                        animation: true,
                        barPercentage: 0.8,
                        barThickness:
                          filteredChartData.labels.length < 10 ? 60 : null,
                        maxBarThickness: 60,
                        categoryPercentage: 1.0,
                        maintainAspectRatio: true,
                        responsive: true,
                        scales: {
                          y: {
                            grid: {
                              drawBorder: false,
                              lineWidth: 2,
                            },
                            // beginAtZero: true,
                            ticks: {
                              callback: function (value) {
                                return value + " hrs";
                              },
                            },
                          },
                          x: {
                            grid: {
                              drawBorder: false,
                              lineWidth: 2,
                            },
                          },
                        },
                        plugins: {
                          tooltip: {
                            callbacks: {
                              label: function (context) {
                                let label = context.dataset.label || "";
                                if (label) {
                                  label += ": ";
                                }
                                label +=
                                  formatHoursToHHMM(context.raw) + " hrs";
                                return label;
                              },
                            },
                          },
                        },
                      }}
                      height={"80%"}
                    />
                  </Card>
                </div>
              </div>
            {/* Table */}
            <Table
              bordered
              className="resource-table"
              columns={tableColumns}
              dataSource={filteredTableData}
              pagination={{ pageSize: tablePageSize }}
              style={{ marginTop: "20px" }}
              // loading={loading}
            />
            </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Analysis;

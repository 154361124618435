import _ from 'lodash';
import React, { useState, useEffect } from "react";
import { Space, Table, Button, Typography } from "antd";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import reports from "../../firebaseService/reportsFunctions";
import ExportUserSummary from "../Common/ExportUserSummary";
import moment from "moment";
import colors from "../../constants/color";
import { DatePicker} from "antd";
import { analytics } from '../../firebase';
import { logEvent } from '@firebase/analytics';

const { Text } = Typography;
function getTotalDaysInMonth(year, month) {
  const nextMonth = new Date(year, month, 1);
  nextMonth.setDate(nextMonth.getDate() - 1);
  return nextMonth.getDate();
}
export default function Workload(props) {
  const [loading, setLoading] = useState(true);
  const [month, setMonth] = useState(moment().format('YYYY-MM'));
  const [monthName, setMonthName] = useState('');
  const [monthDays, setMonthDays] = useState(0);
  const [userList, setUserList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [rowKeys, setRowKeys] = useState([]);
  const [uniqueKeySet, setUniqueKeySet] = useState([]);  
  const disabledDate = (current) => {
    return current && current > moment().endOf('month');
};
const db = firebase.firestore();
const days = Array.from({ length: monthDays}, (_, index) => {
  const dayNumber = index + 1;
  return dayNumber < 10 ? `0${dayNumber}` : dayNumber.toString();
});
const expandedRowRender = (record) => {
  const childColumn = [
    {
      title: "Project Name",
      key: "projectName",
      dataIndex: "projectName",
      width: 150,
      fixed: "left",
      onFilter: (value, record) => record.projectName.indexOf(value) > -1,
      sorter: (a, b) => a?.projectName?.localeCompare(b?.projectName),
      render: (_, record) => record.projectName,
    },
    {
      title: "Total",
      // colSpan: 2,
      dataIndex: "total",
      key: "total",
      align: "center",
      width: 80,
      onCell: (record) => {
        return {
          style: {
            background: colors.RESOURCE_TOTAL_BG,
            textAlign: "center", 
          },
        };
      },
      render: (total) => total,
    },
    ...days.map((day) => ({
      title: `${monthName} ${day}`,
      dataIndex: `${day}`,
      key: `${day}`,
      align: "center",
      width: 80,
      onCell: (record) => {
        const [year] = month?.split("-");
        const monthIndex = moment().month(monthName).month();
        const fullDate = `${year}-${(monthIndex + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        const isWeekend = [6, 0].includes(moment(fullDate).day()); // Saturday = 6, Sunday = 0
        let cellStyle = {};
        cellStyle =
        {backgroundColor: isWeekend
          ? colors.WEEKEND_BG 
          : "transparent", 
      };
        return {
          style: cellStyle, 
        };
      },
      render: (text, record) => {
        // Find the corresponding estimate for that day in the result
        const userEstimate = record.estimate; 
        const [year, months] = month?.split('-');
        const fullDate = `${year}-${(moment().month(monthName).month() + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        const estimateValue = userEstimate[fullDate] || "00:00"; 
        const hrsValue = estimateValue?.split(':');
        const isHighEstimate = Number(hrsValue[0]) >= 8 && Number(hrsValue[1]) > 0 ;
        return (
          <Text 
          type={isHighEstimate ? "danger" : undefined} >
            {/* {estimateValue > 0 ? estimateValue : "-"} */}
            {!(Number(hrsValue[0]) == 0 && Number(hrsValue[1]) == 0) ? estimateValue:"-"}
          </Text>
        );

      }
    })),
  ];
  // const childRows = _.get(record, 'projectsArray') || [];
  // let projectData = formatProjectData(childRows, record);
  // projectData = _.sortBy(projectData, 'projectName')
  return (
    <Table
      className="summary"
      loading={loading}
      columns={childColumn}
      dataSource={record.projects}
      rowKey={"projectKey"}
      scroll={{ x: 4000 }}
      size={'small'}
      bordered
      pagination={false}
    />
  );
};

const expandedRow = {
  expandedRowRender,
  expandRowByClick: true, 
  rowKeys,
};
const columns = [
  {
    title: "UserName",
    key: "userName",
    dataIndex: "userName",
    width: 150,
    fixed: "left",
    filters: userList,
    onFilter: (value, record) => record.userName.indexOf(value) > -1,
    sorter: (a, b) => a?.userName?.localeCompare(b?.userName),
    render: (_, record) => record.userName,
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    width: 80,
    align: "center",
    onCell: (record) => {
      return {
        style: {
          background: colors.RESOURCE_TOTAL_BG,
          textAlign: "center", 
        },
      };
    },
    render: (text, record) => {
      return record.total === 0 ? "-" : record.total; 
    },
  },
  ...days.map(day => ({
    title: `${monthName} ${day}`, 
    dataIndex: `${day}`, 
    key: `${day}`, 
    align: "center",
    width: 80,
    onCell: (record) => {
      const [year] = month?.split("-");
      const monthIndex = moment().month(monthName).month();
      const fullDate = `${year}-${(monthIndex + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      const isWeekend = [6, 0].includes(moment(fullDate).day()); // Saturday = 6, Sunday = 0
      let cellStyle = {};
      cellStyle =
      {backgroundColor: isWeekend
        ? colors.WEEKEND_BG 
        : "transparent", 
    };
      return {
        style: cellStyle, 
      };
    },
    render: (text, record) => {
      const userEstimate = record.estimate; 
      const [year, months] = month?.split('-');
      const fullDate = `${year}-${(moment().month(monthName).month() + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      const estimateValue = userEstimate[fullDate] || "00:00"; 
      const hrsValue = estimateValue?.split(":");
      const isHighEstimate = Number(hrsValue[0]) >= 8 && Number(hrsValue[1]) > 0 ;
      return (
        <Text 
        type={isHighEstimate ? "danger" : undefined} >
          {/* {estimateValue > 0 ? estimateValue : "-"} */}
          {/* {Number(hrsValue[0])>0?estimateValue:"-"} */}
          {!(Number(hrsValue[0]) === 0 && Number(hrsValue[1]) === 0) ? estimateValue : "-"}
        </Text>
      );
    }
  }))
];

const onChange = (date, dateString) => {
  setMonth(dateString);
};

const keyOrder = ['userName', 'projectName', 'projectKey', 'total'];
let dayKeyArray = [];

//For Export
useEffect(() => {
  let formattedData = [];
  let dayKeyArray = [];  // Array to keep track of all day keys

  dataSource.forEach((user) => {
      const { userName, projects, total, estimate } = user;
      const [year, months] = month?.split('-');
      const monthAbbreviation = moment(months, "MM").format("MMM");
      const getAllDatesInMonth = (monthName, year) => {
          const numDaysInMonth = moment(`${year}-${monthName}`, "YYYY-MM").daysInMonth();
          return Array.from({ length: numDaysInMonth }, (_, index) => (index + 1).toString().padStart(2, '0'));  // Generate 01, 02, ..., 31
      };
      const allDaysInMonth = getAllDatesInMonth(monthName, moment().year());
      const userDayData = allDaysInMonth.reduce((acc, day) => {
          const estimatedKey = `${monthAbbreviation}-${day}_EstimatedHrs`;  
          dayKeyArray.push(estimatedKey,monthAbbreviation);  

          const fullDate = `${year}-${(moment().month(monthName).month() + 1).toString().padStart(2, '0')}-${day}`; 
          const estimatedValue = estimate[fullDate] !== undefined ? estimate[fullDate] : '-';
          acc[estimatedKey] = estimatedValue
          return acc;
      }, {});
      const userTotal = total !== undefined ? total: '-';
      formattedData.push([
          {
              userName: userName,
              projectName: 'All Project Summary',
              projectKey: null,
              total: userTotal,
              ...userDayData,  
          },
      ]);
      const projectRows = projects.map((project) => {
          const { projectName, projectKey, total, estimate } = project;
          const dayData = allDaysInMonth.reduce((acc, day) => {
              const estimatedKey = `${monthAbbreviation}-${day}_EstimatedHrs`;  
              dayKeyArray.push(estimatedKey);  
              const fullDate = `${year}-${(moment().month(monthName).month() + 1).toString().padStart(2, '0')}-${day}`; 
              const estimatedValue = estimate[fullDate] !== undefined ? estimate[fullDate] : '-';
              acc[estimatedKey] = estimatedValue
              return acc;
          }, {});
          const projectTotal = total !== undefined ? total : '-';
          return {
              userName: userName,
              projectName: projectName,
              projectKey: projectKey,
              total: projectTotal,
              ...dayData,  
          };
      });
      formattedData.push(projectRows);
  });
  formattedData = formattedData.flat();
  const uniqueDayKeys = [...new Set(dayKeyArray)].sort();
  const finalKeyArray = keyOrder.concat(uniqueDayKeys);
  const rearrangedData = formattedData.map((item) => {
      const transformedRow = {};
      finalKeyArray.forEach((key) => {
          transformedRow[key] = item[key] || null;  
      });
      return transformedRow;
  });
  setExportData(rearrangedData);
  setUniqueKeySet(finalKeyArray);
}, [dataSource]);  // The effect depends on dataSource

  
useEffect(() => {
  if (month != null && month != '') {
    const [year, months] = month?.split('-');
    const date = new Date(year, months - 1, 1);
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    setMonthName(monthNames[date.getMonth()])
    let totalDays = getTotalDaysInMonth(year, months)
    setMonthDays(totalDays)
  }
}, [month])

function getMonthStartAndEndDate(year, month) {
  const startDate = new Date(Date.UTC(year, month - 1, 1)); 
  const endDate = new Date(Date.UTC(year, month, 0)); 
  return {
      startDate: startDate.toISOString().split("T")[0], 
      endDate: endDate.toISOString().split("T")[0], 
  };
}

useEffect(() => {
  if (month != null && month != '') {
    setLoading(true);
    const [year, months] = month.split('-');
    const parsedYear = parseInt(year, 10);
    const parsedMonth = parseInt(months, 10);
    
    const { startDate, endDate } = getMonthStartAndEndDate(parsedYear, parsedMonth);
    reports.getAllResources(startDate,endDate).then((result) => {
      if (result) {
        console.log('API Response:', result); 
        const dataSource = Object.keys(result).map(userKey => {
          const user = result[userKey];
          return {
            userKey: userKey,
            userName: user.userName,
            estimate: user.totalEstimate,
            total:user.total,
            projects: user.projects,
          };
        });
        // console.log("dataSource",result,dataSource)

        setDataSource(dataSource);
      } else {
          console.error('Failed to fetch resources'); 
      }
      setLoading(false);
    });
  }
}, [month, monthDays]);
useEffect(() => {
    const expandedRowKeys = dataSource.map((record) => record.key);
    setRowKeys(expandedRowKeys);
    const userNames = dataSource.map(record => ({ text: record.userName, value: record.userName }));
    setUserList(userNames);
}, [dataSource]);
  
useEffect(() =>{
  logEvent(analytics,"resource workload" , { "account_id": localStorage.getItem("accountId") });
}, [])

  return (
    <div class="card mb-4">
      <div class="card-header py-3">
        <div className="float-left custom-datepicker">
          <DatePicker
            defaultValue={month != '' ? moment(month) : moment()}
            style={{ width: '50%' }}
            onChange={onChange}
            allowClear={false}
            picker="month"
            // disabledDate={disabledDate}
          />
        </div>
        <div className="float-right">
          <Space direction="horizantal">
            <ExportUserSummary
              uniqueKeySet={uniqueKeySet}
              exportInfo={{
                exportData: exportData,
                from: "workload",
              }}
            />
          </Space>
        </div> 
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        rowKey={"userKey"}
        scroll={{ x: 4000, y: 400 }} 
        expandable={{
          ...expandedRow, 
          columnWidth: 30, 
        }}
        bordered
        size={'small'}
      />      
      <br />
    </div>    
  );
}
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import firestore from "../../firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import _ from "lodash";
import moment from "moment";
import {
  Popconfirm,
  Table,
  Button,
  Select,
  Modal,
  Tooltip,
  Dropdown,
} from "antd";
import { Space } from "antd";
import { read, utils, writeFile } from "xlsx";
import {
  UpCircleOutlined,
  DownCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import TimeEntryFormModel from "./TimeEntryFormModel";
import DateRange from "../../common/DateRange";
import {
  collection,
  query,
  where,
  onSnapshot,
  or,
  and,
} from "firebase/firestore";
import teamFunctions from "../../firebaseService/teamFunctions";
import toastHandler from "../../firebaseService/toastHandler";
import { constants } from '../../constants';
import { useSelector } from 'react-redux';
import { analytics } from "../../firebase";
import { logEvent } from "@firebase/analytics";

function TimeSheetTableView() {
  const [loading, setLoading] = useState(true);
  let loggedInUser = useSelector(state => state.userSlice.user)
  let navigate = useNavigate();
  if(loggedInUser && !loggedInUser.hasTeam){
    navigate('/master/AllSetting')
  }
  const [dataSource, setDataSource] = useState([]);
  const [orgDataSource, setOrgDataSource] = useState([]);
  const [outerColumns, setOuterColumns] = useState([]);
  const [expandableObj, setExandableObj] = useState(null);
  const [rowKey, setRowKey] = useState("id");
  const [recordData, setRecordData] = useState({});
  const [show, setShow] = useState(false);
  const [projectfilteredInfo, setProjectFilteredInfo] = useState("");
  const [groupByKey, setGroupByKey] = useState(null);
  const [projectIds, setProjectIds] = useState([]);
  const [projects, setProjects] = useState({});
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [startDocument, setStartDocument] = useState(0);
  const [loadMore, setLoadMore] = useState(true);
  const [realTimeData, setRealTimeData] = useState([]);
  const [listeners, setListeners] = useState([]);

  const dateFormat = constants.dateTime.DATE;
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format(dateFormat)
  );
  const [endDate, setEndDate] = useState(moment().format(dateFormat));

  let projectsList = [];

  const user = firebase.auth().currentUser;

  useEffect(() =>{
    logEvent(analytics,"TimeSheetTableView" , { "account_id": localStorage.getItem("accountId") });
  }, [])

  const FormModal = () => {
    if (show) {
      return (
        <Modal
          style={{ maxWidth: "400px" }}
          title="Time Entry"
          destroyOnClose={true}
          open={show}
          onOk={() => setShow(false)}
          onCancel={() => setShow(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
        >
          {recordData.id && (
            <TimeEntryFormModel
              timeSheet={{
                id: recordData.id,
                // workSpace: recordData.workSpace,
                task: recordData.task,
                project: recordData.project,
                description: recordData.description,
                logHours: recordData.logHours,
                logDate: recordData.logDate,
                billable: recordData.billable,
                currentForm: "timeSheet",
              }}
              dataSource={dataSource}
              onCancel={() => setShow(false)}
            />
          )}
          {!recordData.id && (
            <TimeEntryFormModel
              dataSource={dataSource}
              onCancel={() => setShow(false)}
            />
          )}
          {/* <TimeEntryFormModel timeSheet={{...recordData}}  dataSource={dataSource} onCancel={() => setShow(false)}  /> */}
          {/* onCancel={() => setShow(false)}  */}
        </Modal>
      );
    } else {
      return <> </>;
    }
  };

  useEffect(() => {
    // fetchMyProjects(); All code in this function just cut-paste to detach "firestore snapshot listener"

    const teamsRef = collection(firestore, "teams");
    if (user != null && user != undefined && loggedInUser && loggedInUser.userRole != undefined) {
      let q = query(
        teamsRef,
        and(
          where("isDeleted", "==", false),
          where("accountId", "==", localStorage.getItem("accountId")),
          or(
            where("projectManager.userId", "==", user.uid),
            where("teamCreator.userId", "==", user.uid),
            where("lead.userId", "==", user.uid)
          )
        )
      );
      if(loggedInUser.userRole === constants.usersRole.SUPER_ADMIN){
        q = query(
          teamsRef,
          and(
            where("isDeleted", "==", false),
            where("accountId", "==", localStorage.getItem("accountId"))
          )
        );
        console.log("inside super admin")
      }

      const adminteamsListSnapshot = onSnapshot(
        q,
        (querySnapshot) => {
          const projectKeys = [];
          const myProjects = {};
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            projectKeys.push(data.project.key);
            myProjects[data.project.key] = data.teamName;
          });
          setProjects(myProjects);
          setProjectIds(projectKeys);
          console.log("inside teamsListSnapshot");
          setLoading(false);
        },
        (error) => {
          console.log("inside teamsListSnapshot", error.message);
          toastHandler.error("Error while fetching team list");
        }
      );

      return () => {
        adminteamsListSnapshot(); // This detach "firestore snapshot listener"
        console.log("componentWillUnmount");
      };
    }
  }, []);
  
  useEffect(() => {
    if (user && projectIds.length > 0) {
      var db = firebase.firestore();
      const accountId = localStorage.getItem("accountId");
      const timeEntryRef = db.collection("timeEntry"); // Create a query against the collection

      var listenersArray = listeners;
      var dataArray = [];

      const start = new Date(new Date(startDate).setUTCHours(-5, -30, 0) + 24*60*60*1000);
      const end = new Date(new Date(endDate).setUTCHours(18, 29, 59) + 24*60*60*1000);
      let queryRef = timeEntryRef
        .where("accountId", "==", accountId)
        .where("deleted", "==", false)
        .where("logDate", ">=", start)
        .where("logDate", "<=", end)
        .where("approvers", "array-contains", user.uid)
        .orderBy("logDate", "desc")

      if(startDocument!==0){
        queryRef = queryRef.startAfter(startDocument)
      }
      
      queryRef
        .limit(pageSize)
        .get()
        .then((snapshots) => {
          if (snapshots.docs.length) {
            snapshots.docs.forEach((doc) => {
              let data = doc.data();
              data.id = doc.id;
              data.logDate = moment(data.logDate.seconds * 1000).format(
                constants.dateTime.DATE
              );
              data.teamName = projects[parseInt(data.project.key)];
              dataArray.push(data);
            });
            // previous starting boundary becomes new ending boundary
            let startDoc = snapshots.docs[snapshots.docs.length - 1];
            setStartDocument(startDoc);

            if (dataArray.length < pageSize) {
              setHasMoreData(false);
            }
            // add newly fetched records
            let newDataArray = orgDataSource.concat(dataArray);
            setOrgDataSource(newDataArray);

            //create listener for every document
            let listener = timeEntryRef
              .where("accountId", "==", accountId)
              .where("deleted", "==", false)
              .where("approvers", "array-contains", user.uid)
              .where("logDate", ">=", start)
              .where("logDate", "<=", end)
              .orderBy("logDate", "desc")
              .startAt(snapshots.docs[0])
              .endAt(snapshots.docs[snapshots.docs.length - 1])
              .onSnapshot((docs) => {
                let updatedData = [];
                docs.forEach((doc) => {
                  let data = doc.data();
                  data.id = doc.id;
                  data.logDate = moment(data.logDate.seconds * 1000).format(
                    constants.dateTime.DATE
                  );
                  data.teamName = projects[parseInt(data.project.key)];
                  updatedData.push(data);
                });

                setRealTimeData(updatedData);
              });

            listenersArray.push(listener);
            setListeners(listenersArray);

            setLoading(false);

            // dispatch(setUserTimesheetData([newDataArray]));
          } else {
            setLoading(false);
            setHasMoreData(false);
            toastHandler.info("No more records.");
          }
        });
    } else {
      setLoading(false);
    }
  }, [startDate, endDate, loadMore, projectIds]);

  useEffect(() => {
    return () => {
      detachListeners(); // This detach "firestore snapshot listener"
      console.log("componentWillUnmount");
    };
  }, []);

  function detachListeners() {
    listeners.forEach((listener) => listener());
  }

  useEffect(() => {
    if (realTimeData.length && orgDataSource.length) {
      const arrCopy = [...orgDataSource];
      for (let i = 0; i < orgDataSource.length; i++) {
        for (let j = 0; j < realTimeData.length; j++) {
          if (orgDataSource[i]["id"] === realTimeData[j]["id"]) {
            arrCopy[i] = realTimeData[j];
          }
        }
      }

      let finalDataSource = _.cloneDeep(arrCopy);
      setOrgDataSource(finalDataSource);
    }
  }, [realTimeData]);
  
  const displayUpdates = () => {
    if (groupByKey != null) {
      changeDataFormat(
        groupBy(orgDataSource, groupByKey),
        groupByKey,
        projectfilteredInfo
      );
    } else {
      setDataSource(orgDataSource);
    }
  };

  useEffect(() => {
    displayUpdates();
  }, [groupByKey, orgDataSource]);

  const taskList = [];
  for (let i = 0; i < orgDataSource.length; ++i) {
    let projectNameString = "";
    projectNameString += orgDataSource.map(function (element) {
      return element.project?.value;
    });

    taskList.push({
      key: i.toString(),
      date: orgDataSource[i][0]?.logDate, //logDate
      // date: moment(new Date(orgDataSource[i][0]?.logDate)).format('DD/MM/YYYY'), //logDate
      projectString: projectNameString,
      hours: "", //logDate
      data: orgDataSource[i],
    });
    projectsList.push(orgDataSource[i]?.project.value);
  }

  projectsList = projectsList
    .filter(function (v, i, self) {
      return i == self.indexOf(v);
    })
    .map(function (value) {
      return { text: value, value: value };
    });

  const initOuterColumns = [
    {
      title: "Date",
      dataIndex: "logDate",
      key: "logDate",
      width: constants.width.dateColumnWidth,
      ellipsis: true,
      sorter: (a, b) => moment(a.logDate).unix() - moment(b.logDate).unix(),
    },
    {
      title: "User",
      dataIndex: "userName",
      key: "userName",
      sorter: (a, b) => a?.userName?.localeCompare(b?.userName),
      width: constants.width.userColumnWidth,
      ellipsis: true,
    },
    {
      title: "Team",
      dataIndex: "teamName",
      key: "teamName",
      sorter: (a, b) => a?.teamName?.localeCompare(b?.teamName),
      width: constants.width.teamColumnWidth,
      ellipsis: true,
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      width: constants.width.projectColumnWidth,
      ellipsis: true,
      filters: projectsList,
      onFilter: (value, record) =>
        record.project?.value
          ? record.project?.value === value
          : record.projectString?.indexOf(value) > -1,
      sorter: (a, b) => a?.project?.value?.localeCompare(b?.project?.value),
      render: (_, record) => record?.project?.value || "-",
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
      width: constants.width.taskColumnWidth,
      ellipsis: true,
      render: (_, record) => record?.task?.value,
    },
    {
      title: "Hour(s)",
      dataIndex: "logHours",
      key: "logHours",
      width: constants.width.hoursColumnWidth,
      render: (_, record) => (
        <>
          {" "}
          {record.logHours}{" "}
          <span className="hrs">
            {" "}
            {record?.logHours?.split(":")[0] === "00" ? "Mins" : "Hrs"}
          </span>{" "}
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: constants.width.descriptionClomnWidth,
      ellipsis: true,
      render: (e, record) => (
        <Tooltip placement="leftBottom" title={record.description}>
          {_.capitalize(_.trim(record.description)) || '-'}
        </Tooltip>
      ),
    },
    {
      title: "Billable",
      dataIndex: "billable",
      key: "billable",
      align: "center",
      width: constants.width.billableColumnWidth,
      render: (billable) =>
        billable ? (
          <Tooltip placement="right" title={"Biilable"}>
            {" "}
            <span className="billable">
              {" "}
              <CheckOutlined />
            </span>{" "}
          </Tooltip>
        ) : (
          <Tooltip placement="right" title={"Non biilable"}>
            {" "}
            <span className="billable"> </span>
            <span className="non-billable">
              {" "}
              <CloseOutlined />{" "}
            </span>{" "}
          </Tooltip>
        ),
    },
    {
      title: "Status",
      dataIndex: "approvalStatus",
      key: "approvalStatus",
      width: constants.width.statusColumnWidth,
      ellipsis: true,
      render: (approvalStatus) => (
        <span className={approvalStatus.toLowerCase()}>{approvalStatus}</span>
      ),
    },
  ];

  useEffect(() => {
    setOuterColumns(initOuterColumns);
  }, [projectsList.length]);

  const handleEdit = (row) => {
    setRecordData(row);
    setShow(!show);
  };

  const handleAdd = () => {
    console.log("in add");
    setRecordData({});
    setShow(true);
  };

  const handleDelete = (id) => {
    console.log("in delete");
    console.log(id);
    console.log(dataSource);
    const newData = dataSource.filter((item) => item.id !== id);
    console.log(newData);
    setDataSource(newData);
    if (id) {
      firestore
        .collection("timeEntry")
        .doc(id)
        .update({
          deleted: true,
        })
        .then((docRef) => {
          console.log("updated");
        });
    }
  };

  const ExpandedRowRender = (props) => {
    const innerColumns = [
      {
        title: "Date",
        dataIndex: "logDate",
        key: "logDate",
        width: constants.width.dateColumnWidth,
        ellipsis: true,
        sorter: (a, b) => moment(a.logDate).unix() - moment(b.logDate).unix(),
      },
      {
        title: "User",
        dataIndex: "userName",
        key: "userName",
        width: constants.width.userColumnWidth,
        ellipsis: true,
        sorter: (a, b) => a?.userName?.localeCompare(b?.userName),
      },
      {
        title: "Team",
        dataIndex: "teamName",
        key: "teamName",
        width: constants.width.teamColumnWidth,
        ellipsis: true,
        sorter: (a, b) => a?.teamName?.localeCompare(b?.teamName),
      },
      {
        title: "Project",
        dataIndex: "projectName",
        key: "project",
        width: constants.width.projectColumnWidth,
        ellipsis: true,
        filteredValue: projectfilteredInfo || null,
        onFilter: (value, record) => record.projectName === value,
        sorter: (a, b) => a?.project?.value?.localeCompare(b?.project?.value),
      },
      {
        title: "Task",
        dataIndex: "task",
        key: "task",
        width: constants.width.taskColumnWidth,
        ellipsis: true,
        render: (_, record) => record.task.value || "-",
      },
      {
        title: "Hour(s)",
        dataIndex: "hours",
        key: "hours",
        width: constants.width.hoursColumnWidth,
        render: (_, record) => (
          <>
            {" "}
            {record.hours}{" "}
            <span className="hrs">
              {" "}
              {record.hours.split(":")[0] === "00" ? "Mins" : "Hrs"}
            </span>{" "}
          </>
        ),
      },
      {
        title: "Descr",
        dataIndex: "description",
        key: "description",
        width: constants.width.hoursColumnWidth,
        ellipsis: {
          showTitle: false,
        },
        render: (e, record) => (
          <Tooltip placement="leftBottom" title={record.description}>
            {_.capitalize(_.trim(record.description)) || '-'}
          </Tooltip>
        ),
      },
      {
        title: "Billable",
        dataIndex: "billable",
        key: "billable",
        align: 'center',
        width: constants.width.billableColumnWidth,
        render: (billable) =>
          billable ? (
            <Tooltip placement="right" title={"Biilable"}>
              {" "}
              <span className="billable">
                {" "}
                <CheckOutlined />
              </span>{" "}
            </Tooltip>
          ) : (
            <Tooltip placement="right" title={"Non biilable"}>
              {" "}
              <span className="billable"> </span>
              <span className="non-billable">
                {" "}
                <CloseOutlined />{" "}
              </span>{" "}
            </Tooltip>
          ),
      },
      {
        title: "Status",
        dataIndex: "approvalStatus",
        key: "approvalStatus",
        width: constants.width.statusColumnWidth,
        ellipsis: true,
        render: (approvalStatus) => (
          <span className={approvalStatus.toLowerCase()}>{approvalStatus}</span>
        ),
      },
    ];
    const dataRows = props.record.data.map((item, index) => ({
      ...item,
      key: item.id,
      date: item.logDate,
      hours: item.logHours,
      user: item.userName,
      billable: item.billable,
      approvalStatus: item.approvalStatus,
      logCost: "-",
      invoiceStatus: item.invoiceStatus,
      userName: item.userName,
      projectName: item.project?.value,
      task: item.task,
      teamName: item.teamName,
      // workSpace: item.workSpace
    }));

    return (
      <div className="inner-table">
        <Table
          rowKey={"key"}
          columns={innerColumns}
          showHeader={true}
          dataSource={dataRows}
          pagination={{ pageSize: 5 }}
          size="small"
          scroll={{ x: 1300 }}
        />
      </div>
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };

  function groupBy(collection, property) {
    var i = 0,
      val,
      index,
      values = [],
      result = [];
    for (; i < collection.length; i++) {
      val =
        typeof collection[i][property] == "object"
          ? collection[i][property].value
          : collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  function changeDataFormat(groupedClientData, groupByCol, filters) {
    setProjectFilteredInfo(filters);
    let taskListNew = [];
    for (let i = 0; i < groupedClientData.length; ++i) {
      let projectNameString = "";
      projectNameString += groupedClientData[i].map(function (element) {
        return element.project?.value;
      });
      // console.log(projectNameString)
      // console.log('----')
      taskListNew.push({
        key: i.toString(),
        logDate: groupedClientData[i][0]?.logDate, //logDate
        projectString: projectNameString,
        project: "", //project
        userName: groupedClientData[i][0]?.userName, //user
        approvalStatus: groupedClientData[i][0]?.approvalStatus, //approvalStatus
        data: groupedClientData[i],
      });
    }
    // console.log(filters)

    setDataSource(taskListNew);

    const outerColumnsNew = [
      {
        title: `Grouped by ${groupByCol
          .replace("approvalStatus", "status")
          .replace("logDate", "date")
          .replace("userName", "user")}`,
        dataIndex: groupByCol,
        key: groupByCol,
        width: 200,
        render: (_, record) =>
          typeof record["data"][0][groupByCol] == "object"
            ? record["data"][0][groupByCol]["value"]
            : record[groupByCol],
      },
      { title: "", dataIndex: "project" },
      { title: "" },
      { title: "" },
      { title: "" },
      { title: "" },
      { title: "" },
      { title: "" },
    ];
    setOuterColumns(outerColumnsNew);
    setExandableObj({
      expandedRowRender: (record, projectfilteredInfo) => (
        <ExpandedRowRender record={record} filters={projectfilteredInfo} />
      ),
      expandIcon: ({ expanded, onExpand, record }) =>
        expanded ? (
          <UpCircleOutlined onClick={(e) => onExpand(record, e)} />
        ) : (
          <DownCircleOutlined onClick={(e) => onExpand(record, e)} />
        ),
    });
    setRowKey("key");
  }

  const setInitData = () => {
    setDataSource(orgDataSource);
    setOuterColumns(initOuterColumns);
    setExandableObj(null);
    setGroupByKey(null);
    setRowKey("id");
  };

  const groupTableRows = (selectedOption, filters) => {
    let opt = selectedOption;
    let groupByKeyArr = [];
    groupByKeyArr["logDate"] = "groupByDate";
    groupByKeyArr["approvalStatus"] = "groupByStatus";
    groupByKeyArr["project"] = "groupByProject";
    groupByKeyArr["userName"] = "groupByUser";

    if (groupByKeyArr[selectedOption]) {
      console.log("groupTableRows", groupByKeyArr[selectedOption]);
      console.log("groupTableRows", opt);
      opt =
        typeof selectedOption == "object"
          ? selectedOption.value
          : groupByKeyArr[selectedOption];
    } else {
      opt = selectedOption.value;
    }

    switch (opt) {
      case "clear":
        setInitData();
        break;
      case "groupByDate":
        setGroupByKey("logDate");
        changeDataFormat(groupBy(orgDataSource, "logDate"), "logDate", filters);
        break;
      case "groupByStatus":
        setGroupByKey("approvalStatus");
        changeDataFormat(
          groupBy(orgDataSource, "approvalStatus"),
          "approvalStatus",
          filters
        );
        break;
      case "groupByProject":
        setGroupByKey("project");
        changeDataFormat(groupBy(orgDataSource, "project"), "project", filters);
        break;
      case "groupByUser":
        setGroupByKey("userName");
        changeDataFormat(
          groupBy(orgDataSource, "userName"),
          "userName",
          filters
        );
        break;
      default:
    }
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("pagination", pagination, extra);
    setCurrentPage(pagination.current);
    setProjectFilteredInfo(filters.project);
  };

  const updateDateRange = (range = []) => {
    if (range !== null) {
      const dateRange = range.map((date) => moment(date).format(dateFormat));
      const [start, end] = dateRange;

      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(moment().startOf("month").format(dateFormat));
      setEndDate(moment().format(dateFormat));
    }
    setStartDocument(0);
    setOrgDataSource([]);
    setDataSource([]);
    setLastPage(0);
    setCurrentPage(1);
    setHasMoreData(true);
  };

  /* get state data and export to XLSX */
  const exportFile = useCallback(
    (fileType) => {
      console.log(fileType);
      let exportData = [];
      orgDataSource.forEach((record, index) => {
        exportData.push({
          Index: index + 1,
          Date: moment.utc(record.logDate).format(constants.dateTime.DATE),
          Username: record.userName,
          Team: record.teamName,
          Project: record.project.value,
          Task: record.task.value,
          Hours: record.logHours,
          Billable: record.billable ? "Billable" : "Non billable",
          Status: record.approvalStatus,
        });
      });
      /* generate worksheet */
      const ws = utils.json_to_sheet(exportData);
      /* create workbook and append worksheet */
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      /* export to XLSX */
      writeFile(
        wb,
        `${"timesheet-" + moment().format("DD-MM-YYYY-hh-mm") + fileType}`
      );
    },
    [orgDataSource]
  );

  const itemRender = (_, type, originalElement) => {
    setLastPage(_);
    if (type === "prev") {
      return <Button type="text"> Prev </Button>;
    }
    if (type === "next") {
      return (
        <>
          <Button
            type="text"
            disabled={!hasMoreData && currentPage === lastPage}
            onClick={() =>
              currentPage === lastPage
                ? (setLoadMore(!loadMore), setCurrentPage(currentPage + 1))
                : ""
            }
          >
            Next
          </Button>
        </>
      );
    }
    return originalElement;
  };

  const items = [
    {
      key: "1",
      onClick: () => exportFile(".xlsx"),
      label: <span> XLSX (MS Excel) </span>,
    },
    {
      key: "2",
      onClick: () => exportFile(".xls"),
      label: <span> XLS (MS Excel 1997-2004) Compatible </span>,
    },
    {
      key: "3",
      onClick: () => exportFile(".csv"),
      label: <span> CSV (Comma Seperated Value) </span>,
    },
  ];

  return (
    <div class="card mb-4">
      <div class="card-header py-3">
        <DateRange updateDateRange={updateDateRange} dateFormat={dateFormat} />
        <div className="float-right">
          <Space direction="horizantal">
            <Select
              labelInValue
              placeholder="Group by"
              style={{ width: 160 }}
              disabled={!dataSource.length}
              onChange={(selectedOption) =>
                groupTableRows(selectedOption, projectfilteredInfo)
              }
              options={[
                { value: "clear", label: "Clear" },
                { value: "groupByDate", label: "Group By Date" },
                { value: "groupByUser", label: "Group By User" },
                { value: "groupByStatus", label: "Group By Status" },
                { value: "groupByProject", label: "Group By Project" },
              ]}
            />
            <Dropdown
              menu={{ items }}
              disabled={!dataSource.length}
              placement="bottomLeft"
              arrow
            >
              <Button
                type="primary"
                className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
              >
                <ExportOutlined /> Export As
              </Button>
            </Dropdown>
          </Space>
        </div>
      </div>
      <Table
        loading={loading}
        columns={outerColumns}
        expandable={expandableObj}
        dataSource={dataSource}
        pagination={{
          pageSize: pageSize,
          current: currentPage,
          itemRender: itemRender,
        }}
        onChange={onChange}
        rowKey={rowKey}
        // size={'small'}
        // scroll={{ x: 'auto', y: constants.tableHeight() }}
        scroll={{ x: 1300 }}
      />
      <FormModal />
    </div>
  );
}
export default TimeSheetTableView;
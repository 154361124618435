import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Select, Row, message } from 'antd';
import mondaySdk from "monday-sdk-js";
import toastHandler from '../../../firebaseService/toastHandler';
import MondayConfig from '../../../configs/MondayConfig';
import sentryUtils from '../../../utils/sentryUtils';

const SelectDateColumn = (props) => {
    const { placeholder, data, form, field, cb, error, value, disabled, selectedProject} = props;
    // console.log("sectdate",props)
    const { name } = field;
    const monday = mondaySdk();
    monday.setApiVersion(MondayConfig.MONDAY_API_VERSION)
    const [dateColumns, setDateColumns] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [busy, setBusy] = useState(false);
    const [isFirst, setIsFirst] = useState(true)

    const fetchColumn = async () => {
        console.log('fetchColumn called')
        
        if (
            selectedProject !== null &&
            selectedProject !== '' &&
            selectedProject.key !== undefined
        ) {
            setBusy(true);

            let query = `query {
                boards (ids: ` + selectedProject.key + `) {
                  columns(types: date) {
                    id
                    title
                    type
                  }		
                }
              }`;

            const tokendata = await monday.get('sessionToken');
            const response = await monday.api(query, {
                options: { token: tokendata.data },
            }).catch((err) => {
                setBusy(false);
                console.log('error in fetch column --->', err.message);
                toastHandler.error(err.message);
                sentryUtils.captureMondayException('fetchColumn', error, 'selectDateColumn');
            });

            let columnList = response.data.boards[0]?.columns || [];
            columnList = _.orderBy(columnList, ['title'], ['asc']);
            let columns = [];
            columnList.forEach((item, index) => {
                columns.push({
                    key: item.id,
                    label: item.title,
                    value: item.id,
                });
            });
            if (!_.isEqual(dateColumns, columns)) {
                setDateColumns(columns);
            }
            setBusy(false);
        }
    };

    useEffect(() => {
        if (!_.isEmpty(selectedProject)) {
            fetchColumn();
            setIsFirst(true)
        }
        if (dateColumns.length > 0 && value) {
            const data = dateColumns.filter((item) => item.value === value);
            if (data.length > 0) {
                cb(data);
                if (selectedValue !== data[0]) {
                        setSelectedValue(data[0]);
                }
            }
        }
        // } else if (dateColumns.length === 0 && value) {
        //     message.error("Your previous set date column is deleted from Monday board");
        // }

    }, [selectedProject, dateColumns, value]);

    // useEffect(() => {
    //     // if(name === 'dateColName' && dateColumns.length === 0 && showError === 'yes'){
    //     //     if(!isFirst){
    //     //         if(isSubTask==='yes'){
    //     //             // message.error("Add date column for subitem to your monday board");
    //     //             // toastHandler.error("Add date's column for subitem to your monday board");
    //     //         }
    //     //         else{
    //     //             // message.error("Add date column to your monday board");
    //     //             // toastHandler.error("Add date's column to your monday board");
    //     //         }
    //     //     } else {
    //     //         setIsFirst(false)
    //     //     }
    //     // }
    //     console.log("dateCo",dateColumns,value)
    //     if (['taskStartDateColName', 'taskEndDateColName', 
    //         'subTaskStartDateColName', 'subTaskEndDateColName'].includes(name) && dateColumns.length === 0) {
    //         if(!isFirst){
    //             // message.error("Add date column to your monday board");
    //             // toastHandler.error("Add status column to your monday board");
    //         } else {
    //             console.log("in if else")
    //             setIsFirst(false)
    //         }
    //     }
    //     console.log("date",dateColumns.length,value)
    //     if (dateColumns.length > 0 && value) {
    //         console.log("Date Columns:", dateColumns);
    //         console.log("Value:", value);

    //         const data = dateColumns.filter((item) => item.value === value);
    //         console.log("date",data.length)
    //         if(data.length > 0){
    //             cb(data);
    //             setSelectedValue(data[0]);
    //         } 
    //         // else {
    //         //     setSelectedValue(null);
    //         //     // if(value){
    //         //     //     message.error("Your previouslly set date column is deleted from monday board");
    //         //     //     // toastHandler.error("Your previouslly set date column is deleted from monday board");
    //         //     // }
    //         // }
    //     }
    //     else if (dateColumns.length == 0 && value)   {
    //         // setSelectedValue(null);
    //         message.error("Your previouslly set date column is deleted from monday board");
    //     } 
    //     // else {
    //     //     setSelectedValue(null);
    //     // }
    // }, [dateColumns, value])
    const handleClear = (value) => {
        console.log('clear value:', value);
        cb([null])
        setSelectedValue(null)
    };
    return (
        <div>
            <Row>
                <Select
                    placeholder={placeholder}
                    style={{ width: '100%' }}
                    disabled={disabled}
                    name={name}
                    value={selectedValue}
                    showSearch
                    optionFilterProp='label'
                    onChange={(e) => {
                        const selectedValue = dateColumns.filter((item) => item.value === e);
                        cb(selectedValue)
                    }}
                    loading={busy}
                    allowClear 
                    onClear={handleClear}
                >
                    {dateColumns?.map((item) => (
                        <Select.Option key={item.key} value={item.value} label={item.label}>
                            {item.label}
                        </Select.Option>
                    ))}
                </Select>
            </Row>
            {error ? <Row><span style={{ fontSize: 11, color: 'red' }}>{error}</span></Row> : null}
        </div >
    )
};

export default SelectDateColumn;

const team = {
    TITLE : "Congratulations! The account has been created successfully",
    TITLE0 : "Welcome to TimeRely!",
    TITLE1 : "Create Team - Select Project",
    TITLE2 : "Create Team - Assign column",
    TITLE3 : "Create Team - Select Users",
    TITLE1U : "Update Team - Select Project",
    TITLE2U : "Update Team - Assign column",
    TITLE3U : "Update Team - Select Users",
    DESCRIPTION : "Let's start with creating a team",
    DESCRIPTION0 : "As no teams in the list yet, let's start with creating a team",
    DESCRIPTION1 : "Select workspace & project(monday board) to create a dedicated team.",
    DESCRIPTION2 : "Enter team name and select assignee (people) column.",
    DESCRIPTION3 : "Select Users for various roles (PM, Lead and Member). PM and Lead roles hold the authority to approve timesheets.",

    TITLE_STATUS_1 : "Create Team - Exclude Tasks (Optional)",
    DESCRIPTION_STATUS: 'This functionality is provided to exclude certain status tasks from timesheet entry. Please select applicable status column & status label',
    TITLE_STATUS_2 : "Update Team - Exclude Tasks (Optional)",

    TITLE_Date_C : "Create Team - include Timeline (Optional)",
    DESCRIPTION_DATE: 'This functionality is provided to include certain dates from timesheet entry. Please select applicable date column',
    TITLE_DATE_U : "Update Team - include Timeline (Optional)",
}

export default team;

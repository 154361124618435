import { getFunctions, httpsCallable } from "firebase/functions";
import toastHandler from "./toastHandler";
import {functions} from "../firebase";
import envUtils from "../utils/envUtils";
import sentryUtils from "../utils/sentryUtils";

const reports = {
  customReportsData: async (data) => {
    console.log(data);
    let resData = httpsCallable(functions, "api/getCustomReportData");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/getCustomReportData");
    }
    const timeEntries = await resData(data).catch((error) => {
      toastHandler.error(error);
      sentryUtils.captureAPIException('customReportsData', error, "reportFunctions")
    });

    return timeEntries;
  },

  updateSummaryDoc: async (year) => {
    let resData = httpsCallable(functions, "api/updateProjectSummaryDoc");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/updateProjectSummaryDoc");
    }
    const timeEntries = await resData({
      year: year,
    }).catch((error) => {
      toastHandler.error(error);
      sentryUtils.captureAPIException('updateSummaryDoc', error, "reportFunctions")
    });

    return timeEntries;
  },

  updateMondaySummaryBoard: async (year) => {
    let resData = httpsCallable(functions, "api/updateMondaySummaryBoard");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/updateMondaySummaryBoard");
    }
    const timeEntries = await resData({
      year: year,
    }).catch((error) => {
      toastHandler.error(error);
      sentryUtils.captureAPIException('updateMondaySummaryBoard', error, "reportFunctions")
    });

    return timeEntries;
  },
  updateMondayUserSummaryBoard: async (month_start, month_end) => {
    let resData = httpsCallable(functions, "api/updateMondayUsersSummaryBoard");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/updateMondayUsersSummaryBoard");
    }
    const timeEntries = await resData({
      // start: month_start,
      month: month_end
    }).catch((error) => {
      toastHandler.error(error);
      sentryUtils.captureAPIException('updateMondayUserSummaryBoard', error, "reportFunctions")
    });

    return timeEntries;
  },
  updateUserSummaryDoc: async (month_start, month_end) => {
    let resData = httpsCallable(functions, "api/updateUsersSummaryDoc");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/updateUsersSummaryDoc");
    }
    const timeEntries = await resData({
      start: month_start,
      end: month_end
    }).catch((error) => {
      toastHandler.error(error);
      sentryUtils.captureAPIException('updateUserSummaryDoc', error, "reportFunctions")
    });

    return timeEntries;
  },

  getAllResources: async (startDate, endDate) => {
    let resData = httpsCallable(functions, "api/getAllResources");

    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
        resData = httpsCallable(getFunctions(), "mondaybaseurl/api/getAllResources");
    }

    try {
        const result = await resData({startDate : startDate, endDate : endDate });
        const data = result.data;
        if (!data.error) {
            return data; // Return the result
        } else {
            const error = data.error;
            toastHandler.error(error);
            sentryUtils.captureAPIMessageException('getAllResources', error, "getAllResources");
            return null;
        }
    } catch (error) {
        toastHandler.error("An error occurred while fetching data.");
        sentryUtils.captureAPIException('getAllResources', error, "getAllResources");
        return null;
    }

  },

  syncResourceAnalysis: async () => {
    console.log('inside sync analysis function call')
    let resData = httpsCallable(functions, "api/updateProjectAnalysisDoc");

    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
        resData = httpsCallable(getFunctions(), "mondaybaseurl/api/updateProjectAnalysisDoc");
    }

    try {
        const result = await resData();
        const data = result.data;
        if (!data.error) {
            toastHandler.success(data);
            return data; // Return the result
        } else {
            const error = data.error;
            toastHandler.error(error);
            sentryUtils.captureAPIMessageException('syncResourceAnalysis', error, "syncResourceAnalysis");
            return null;
        }
    } catch (error) {
        toastHandler.error("An error occurred while fetching data.");
        sentryUtils.captureAPIException('syncResourceAnalysis', error, "syncResourceAnalysis");
        return null;
    }

  },

};

export default reports;

import React, { useState, useEffect, Fragment } from "react";
import {
  Table,
  Button,
  Modal,
  Space,
  Tooltip,
} from "antd";
import "firebase/compat/auth";
import firestore from "../../firebase";
import firebase from "firebase/compat/app";
import moment from "moment";
import _ from "lodash";
import TimeEntryFormModel from "../TimeSheetTableView/TimeEntryFormModel";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import Comment from "../Comment/Comment";
import toastHandler from "../../firebaseService/toastHandler";
import { collection, query, where, and, onSnapshot } from "firebase/firestore";
import { constants } from "../../constants";
import DateRange from "../../common/DateRange";
import { analytics } from "../../firebase";
import { logEvent } from "@firebase/analytics";
import sentryUtils from "../../utils/sentryUtils";

export default function RejectedList() {
  const functions = getFunctions();
  const [orgDataSource, setOrgDataSource] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [groupByKey, setGroupByKey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [comment, setComment] = useState("");
  const [password, setPassword] = useState("");
  const [projectIds, setProjectIds] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const [reset, setReset] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState(false);
  const [open, setOpen] = useState(false);
  const [recordData, setRecordData] = useState({});
  const [projectfilteredInfo, setProjectFilteredInfo] = useState("");

  const dateFormat = constants.dateTime.DATE;
  // const [startDate, setStartDate] = useState(
  //   moment().startOf("month").format(dateFormat)
  // );
  // const [endDate, setEndDate] = useState(moment().format(dateFormat));

  const user = firebase.auth().currentUser;

  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [startDocument, setStartDocument] = useState(0);
  const [loadMore, setLoadMore] = useState(true);
  const [realTimeData, setRealTimeData] = useState([]);
  const [listeners, setListeners] = useState([]);

  useEffect(() =>{
    logEvent(analytics,"resubmission" , { "account_id": localStorage.getItem("accountId") });
  }, [])

  useEffect(() => {
    if (user) {
      var db = firebase.firestore();
      const accountId = localStorage.getItem("accountId");
      const timeEntryRef = db.collection("timeEntry"); // Create a query against the collection

      var listenersArray = listeners;
      var dataArray = [];

      // const start = new Date(new Date(startDate).setUTCHours(-5, -30, 0) + 24*60*60*1000);
      // const end = new Date(new Date(endDate).setUTCHours(18, 29, 59) + 24*60*60*1000);
      const queryRef = timeEntryRef
        .where("userId", "==", user.uid)
        .where("approvalStatus", "in", ["Rejected"])
        .where("accountId", "==", accountId)
        .where("deleted", "==", false)
        // .where("logDate", ">=", start)
        // .where("logDate", "<=", end)
        .orderBy("logDate")
        .startAfter(startDocument)
        .limit(pageSize)
        .get()
        .then((snapshots) => {
          if (snapshots.docs.length) {
            snapshots.docs.forEach((doc) => {
              let data = doc.data();
              data.id = doc.id;
              data.logDate = moment(data.logDate.seconds * 1000).format(
                constants.dateTime.DATE
              );
              dataArray.push(data);
            });
            // previous starting boundary becomes new ending boundary
            let startDoc = snapshots.docs[snapshots.docs.length - 1];
            setStartDocument(startDoc);

            if (dataArray.length < pageSize) {
              setHasMoreData(false);
            }
            // add newly fetched records
            let newDataArray = orgDataSource.concat(dataArray);
            setOrgDataSource(newDataArray);

            //create listener for every document
            let listener = timeEntryRef
              .where("userId", "==", user.uid)
              .where("approvalStatus", "in", ["Rejected"])
              .where("accountId", "==", accountId)
              .where("deleted", "==", false)
              // .where("logDate", ">=", start)
              // .where("logDate", "<=", end)
              .orderBy("logDate")
              .startAt(snapshots.docs[0])
              .endAt(snapshots.docs[snapshots.docs.length - 1])
              .onSnapshot((docs) => {
                let updatedData = [];
                docs.forEach((doc) => {
                  let data = doc.data();
                  data.id = doc.id;
                  data.logDate = moment(data.logDate.seconds * 1000).format(
                    constants.dateTime.DATE
                  );
                  updatedData.push(data);
                });

                setRealTimeData(updatedData);
              });

            listenersArray.push(listener);
            setListeners(listenersArray);

            setLoading(false);

            // dispatch(setUserTimesheetData([newDataArray]));
          } else {
            setHasMoreData(false);
            setLoading(false);

            toastHandler.info("No more records.");
          }
        })
        .catch((error) => {
          sentryUtils.captureFirestoreException('RejectedTimeEntries', error, 'RejectedList');
        });
    }
    setLoading(false);
  }, [loadMore, reset]);

  useEffect(() => {
    return () => {
      detachListeners(); // This detach "firestore snapshot listener"
      console.log("componentWillUnmount");
    };
  }, []);

  function detachListeners() {
    listeners.forEach((listener) => listener());
  }

  useEffect(() => {
    if (realTimeData.length && orgDataSource.length) {
      const arrCopy = [...orgDataSource];
      for (let i = 0; i < orgDataSource.length; i++) {
        for (let j = 0; j < realTimeData.length; j++) {
          if (orgDataSource[i]["id"] === realTimeData[j]["id"]) {
            arrCopy[i] = realTimeData[j];
          }
        }
      }

      let finalDataSource = _.cloneDeep(arrCopy);
      setOrgDataSource(finalDataSource);
    }
  }, [realTimeData]);

  function resetPage() {
    console.log('resetPage Called')
    detachListeners()
    setStartDocument(0);
    setOrgDataSource([]);
    setDataSource([]);
    setLastPage(0);
    setCurrentPage(1);
    setHasMoreData(true);
    setReset(!reset)
  }


  function displayList(orgDataSource) {
    // let projectsListTemp = [];
    // for (let i = 0; i < orgDataSource.length; i++) {
    //   projectsListTemp.push(orgDataSource[i]?.project.value);
    // }

    // projectsListTemp = projectsListTemp
    //   .filter(function (v, i, self) {
    //     return i == self.indexOf(v);
    //   })
    //   .map(function (value) {
    //     return { text: value, value: value };
    //   });
    // console.log(orgDataSource)
    // console.log(projectsListTemp)
    setProjectsList(projectsListTemp);
  }

  let projectsListTemp = [];
  for (let i = 0; i < orgDataSource.length;++i) {
    projectsListTemp.push(orgDataSource[i]?.project?.value);
    
  }
  
  projectsListTemp = projectsListTemp
    .filter(function (v, i, self) {
      return i == self.indexOf(v);
    })
    .map(function (value) {
      return { text: value, value: value };
    });

  const handleReSubmit = (row) => {
    console.log("in resubmit");
    console.log(row);
    console.log(row.id);
    setRecordData(row);
    setShow(!show);
  };
  const handleComment = (row) => {
    console.log(row);
    setRecordData(row);
    setOpen(true);
  };

  const columns = [
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      width: constants.width.dateColumnWidth,
      ellipsis: true,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (_, record) => <span>{record.logDate}</span>,
    },
    {
      title: "Project",
      key: "project",
      dataIndex: "project",
      width: constants.width.projectColumnWidth,
      ellipsis: true,
      filters: projectsListTemp,
      // onFilter: (value, record) => record.project.value === value,
      onFilter: (value, record) =>
        record.project?.value
        ? record.project?.value === value
        : record.projectString?.indexOf(value) > -1,
      sorter: (a, b) => a?.project?.value?.localeCompare(b?.project?.value),
      render: (_, record) => <span>{record.project?.value}</span>,
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
      width: constants.width.taskColumnWidth,
      ellipsis: true,
      render: (_, record) => <span>{record.task?.value}</span>,
    },
    {
      title: "Subtask",
      dataIndex: "subtask",
      key: "subtask",
      width: constants.width.taskColumnWidth,
      ellipsis: true,
      render: (_, record) => <span>{record.subtask?.value}</span>,
    },
    {
      title: "Hour(s)",
      key: "logHours",
      dataIndex: "logHours",
      width: constants.width.hoursColumnWidth,
      ellipsis: true,
      render: (_, record) => (
        <>
          {" "}
          {record.logHours}{" "}
          <span className="hrs">
            {" "}
            {record.logHours.split(":")[0] == "00" ? "Mins" : "Hrs"}
          </span>{" "}
        </>
      ),
    },
    {
      title: "Reason",
      key: "reason",
      dataIndex: "reason",
      width: constants.width.reasonColumnWidth,
      ellipsis: true,
      render: (_, record) =>
        record.reviewerComments[record.reviewerComments.length - 1]?.comment,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: constants.width.descriptionClomnWidth,
      ellipsis: {
        showTitle: false,
      },
      render: (e, record) => (
        <Tooltip placement="leftBottom" title={record.description}>
          {_.capitalize(_.trim(record.description)) || '-'}
        </Tooltip>
      ),
    },
    {
      title: "Status",
      key: "status",
      align:'center',
      dataIndex: "status",
      width: constants.width.statusColumnWidth,
      render: (_, record) => (
        <span className={record.approvalStatus.toLowerCase()}>
          {record.approvalStatus}
        </span>
      ),
    },
    {
      title: "Action",
      key: "RejectStatus",
      width: constants.width.statusColumnWidth,
      render: (_, record) =>
        orgDataSource.length >= 1 ? (
          <>
            <Button
              onClick={() => handleReSubmit(record)}
              style={{ background: "#51AB0B", color: "#FFFFFF" }}
              shape="round"
            >
              Resubmit
            </Button>
          </>
        ) : null,
    },
    {
      title: "Comments",
      key: "comment",
      dataIndex: "comment",
      width: constants.width.commentColumnWidth,
      render: (_, record) => (
        <span>
          <Button
            type="primary"
            size="small"
            title="Comment"
            onClick={() => handleComment(record)}
          >
            {/* <div style={{ position: "relative" }}> */}
            <FontAwesomeIcon icon={faComment} />
            {/* <span style={{ position: "absolute" }}>
              <FontAwesomeIcon icon="circle" />
              {record.reviewerComments.length}</span> */}
            {/* </div> */}
          </Button>
        </span>
      ),
    },
  ];
  const FormModal = () => {
    return (
      <Modal
        style={{ maxWidth: "500px" }}
        title="Resubmit Time Entry"
        destroyOnClose={true}
        open={show}
        onOk={() => setShow(false)}
        onCancel={() => setShow(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <TimeEntryFormModel
          timeSheet={{
            id: recordData.id,
            workSpace: recordData.workSpace,
            task: recordData.task,
            project: recordData.project,
            description: recordData.description,
            // length:recordData.reviewerComment.length,
            comment: "",
            logHours: recordData.logHours,
            logDate: recordData.logDate,
            billable: recordData.billable,
            subtask: recordData.subtask,
            currentForm: "reSubmit",
          }}
          resetPage={resetPage}
          dataSource={orgDataSource}
          onCancel={() => setShow(false)}
        />
      </Modal>
    );
  };

  const onClose = () => {
    setOpen(false);
  };

  // const updateDateRange = (range = []) => {
  //   if (range !== null) {
  //     const dateRange = range.map((date) => moment(date).format(dateFormat));
  //     const [start, end] = dateRange;

  //     setStartDate(start);
  //     setEndDate(end);
  //   } else {
  //     setStartDate(moment().startOf("month").format(dateFormat));
  //     setEndDate(moment().format(dateFormat));
  //   }
  //   setStartDocument(0);
  //   setOrgDataSource([]);
  //   setDataSource([]);
  //   setLastPage(0);
  //   setCurrentPage(1);
  //   setHasMoreData(true);
  // };

  const itemRender = (_, type, originalElement) => {
    setLastPage(_);
    if (type === "prev") {
      return <Button type="text"> Prev </Button>;
    }
    if (type === "next") {
      return (
        <>
          <Button
            type="text"
            disabled={!hasMoreData && currentPage === lastPage}
            onClick={() =>
              currentPage === lastPage
                ? (setLoadMore(!loadMore), setCurrentPage(currentPage + 1))
                : ""
            }
          >
            Next
          </Button>
        </>
      );
    }
    return originalElement;
  };

  const onChange = (pagination, filters, sorter, extra) => {
    setCurrentPage(pagination.current);
    setProjectFilteredInfo(filters.project);
  };

  return (
    <div class="card mb-4">
      <div class="card-header py-3">
        {/* <DateRange updateDateRange={updateDateRange} dateFormat={dateFormat} /> */}

        <div className="float-right">
          <Space direction="horizantal"></Space>
        </div>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={orgDataSource}
        scroll={{ x: 1300 }}
        rowKey={"id"}
        pagination={{
          pageSize: pageSize,
          current: currentPage,
          itemRender: itemRender,
        }}
        onChange={onChange}
      />
      <Comment open={open} setOpen={setOpen} commentData={recordData} />
      <FormModal />
    </div>
  );
}
const styles = {
  cardFont: {
    fontFamily: "Poppins",
    letterSpacing: "0em",
    textAlign: "left",
    fontSize: "10px",
  },
  AvtarStyle: {
    backgroundColor: "#1376E7",
    color: "#FFFFFF",
  },
};

import _ from 'lodash';
import { DatePicker, Form, Select, Switch, TimePicker, Button, Input, Descriptions, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState, useEffect } from "react";
import moment from "moment";
import firestore from "../../firebase";
import firebase from "firebase/compat/app";
import { boolean } from "yargs";
import { bool } from "prop-types";
import { collection, query, where, getDocs, or, and,Timestamp } from "firebase/firestore";
import toastHandler from "../../firebaseService/toastHandler";
import { useSelector } from 'react-redux';
import mondaySdk from "monday-sdk-js";
import { constants } from '../../constants';
import MondayConfig from '../../configs/MondayConfig';
import { analytics } from '../../firebase';
import { logEvent } from "@firebase/analytics";
import sentryUtils from '../../utils/sentryUtils';
import {fetchStatusLabel} from '../../mondayService/StatusLabel';

function TimeEntryFormModel(props) {

  const userTimesheet = useSelector(state => state.timesheetSlice.userTimesheetData);
  const user = firebase.auth().currentUser;
  const monday = mondaySdk();
  monday.setApiVersion(MondayConfig.MONDAY_API_VERSION);    
  const [form] = Form.useForm();

  const [selectedProject, setSelectedProject] = useState(props.timeSheet ? props.timeSheet.project : null);

  const [selectedTask, setSelectedTask] = useState(props.timeSheet ? props.timeSheet.task : null);
  const [selectedSubTask, setSelectedSubTask] = useState(props.timeSheet ? props.timeSheet.subtask : null);

  const [logDate, setLogDate] = useState(props.timeSheet ? props.timeSheet.logDate : new Date());
  const [description, setDescription] = useState(props.timeSheet ? props.timeSheet.description : null);
  const [logTime, setLogTime] = useState(props.timeSheet ? props.timeSheet.logHours : "00:00");

  const [billable, setBillable] = useState(props.timeSheet ? props.timeSheet.billable : true);
  const [comment, setComment] = useState(null)
  const [projectData, setProjectData] = useState([]);
  const [assigneeColData, setAssigneeColData] = useState({});
  const [subitemAssigneeColData, setsubitemAssigneeColData] = useState({});
  const [approversData, setApproversData] = useState({});
  const [taskList, setTaskList] = useState([]);
  const [projectDropDownOptions, setProjectDropDownOptions] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [taskDropDownOptions, setTaskDropDownOptions] = useState([]);
  const [showSubtask, setShowSubtask] = useState(props.timeSheet && props.timeSheet.subtask ? true : false);
  const [subTaskData, setSubTaskData] = useState([]);
  const [allSubTaskData, setAllSubTaskData] = useState([]);
  const [subtaskPlaceholder , setSubtaskPlaceholder] = useState("Select subtask");
  const [subTaskDropDownOptions, setSubTaskDropDownOptions] = useState([]);
  const [mePersonData, setMePersonData] = useState([]);
  const [meData, setMeData] = useState([false]);
  var selectTask = props.timeSheet ? props.timeSheet.task : null;
  const [disabled, setDisabled] = useState(props.timeSheet?.currentForm == "reSubmit" ? true : false);
  const [alreadyExists, setAlreadyExists] = useState(false);
  const [existingRecordId, setExistingRecordId] = useState(null);
  const [approved, setApproved] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [taskStatusColumnData, setTaskStatusColumnData] = useState({});
  const [taskStatusLabelData, setTaskStatusLabel] = useState({});

  // console.log(props.timeSheet.comment[props.timeSheet.comment.length-1].comment);
  const handleTaskChange = (key, value) => {
    setSelectedSubTask(null);
    setSubTaskData([]);
    setAllSubTaskData([]);
    setSubtaskPlaceholder("Select subtask")
    form.resetFields(['subtask'])
    setSelectedTask(value);
  };

  const handleSubTaskChange = (key, value) => {
    setSelectedSubTask(value);
  };

  const handleProjectChange = (key, value) => {
    setSelectedProject(value);
    setSelectedTask(null)
    setSelectedSubTask(null)
    form.resetFields(['task'])  
    form.resetFields(['subtask'])
  };


  const handleDescription = event => {
    // 👇️ access textarea value
    setDescription(event.target.value);
  };

  const validateTime = (_, value) => {
    // Check if the selected time is at least one minute
    const selectedTime = moment(value, 'HH:mm');
    const minimumTime = moment().startOf('day').add(1, 'minute');

    if (selectedTime.isBefore(minimumTime)) {
      return Promise.reject(new Error('Please enter a valid time.'));
    }
    return Promise.resolve();
  };

  const handleComment = event => {
    setComment(event.target.value);
  };

  const handleBill = (checked) => {
    if (checked) {
      setBillable(true);
    }
    else {
      setBillable(false);
    }
  };

  const fetchProjects = async () => {
    const accountId = localStorage.getItem("accountId");
    console.log(accountId);

    const teamsRef = collection(firestore, "teams");
    const q = query(
      teamsRef,
      and(
        where("accountId", "==", accountId),
        where("isDeleted", "==", false),
        or(
          where("projectManager.userId", "==", user.uid),
          where("lead.userId", "==", user.uid),
          where("teamCreator.userId", "==", user.uid),
          where("teamMemberIds", "array-contains", user.uid)
        )
      )
    );

    const projects = [];
    const assignee = {};
    const subtaskAssignee = {};
    const approvers = {};
    const taskStatusColumn = {};
    const taskStatusLabel = {};
    const querySnapshot1 = await getDocs(q);
    querySnapshot1.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      const data = doc.data();
      data.id = doc.id;
      projects.push(data.project);
      assignee[doc.id] = data.assigneeColumn;
      subtaskAssignee[doc.id] = data.subitemAssigneeColumn;
      approvers[doc.id] = data.approvers;
      taskStatusColumn[doc.id] = data.taskStatusColumn;
      taskStatusLabel[doc.id] = data.taskStatusLabel;
    });
    // console.log(projects);
    // console.log(assignee);
    setProjectData(projects);
    setAssigneeColData(assignee);
    setsubitemAssigneeColData(subtaskAssignee)
    setApproversData(approvers);
    setTaskStatusColumnData(taskStatusColumn);
    setTaskStatusLabel(taskStatusLabel);
    setMeData(true);

  }

  useEffect(() => {
    fetchProjects();
  }, []);

  function selectProjectDropDown() {
    let project = [];
    console.log("in proj load")
    // console.log(projectData)
    projectData.forEach((item, index) => {
      // console.log(item.key);
      // console.log(item.value);
      project.push({
        key: item.key,
        value: item.value,
      });
    });
    // console.log(project);
    setProjectDropDownOptions(project);
  }

  useEffect(() => {
    selectProjectDropDown();
  }, [meData]);

  const fetchItems = async () => {
    // console.log(selectedProject);
    // console.log("use effect task");
    if (
      selectedProject != null &&
      selectedProject !== "" &&
      selectedProject !== undefined &&
      selectedProject.key !== undefined
    ) {
      let cursorV = null;
      setLoading(true)
      let arr = [];
      console.log("board key " + selectedProject.key);
      let assigneeColumn = assigneeColData[selectedProject.key];
      let taskStatusColumn = taskStatusColumnData[selectedProject.key];
      let taskStatusLabel = taskStatusLabelData[selectedProject.key];
      let labelArray = null;

      if(taskStatusColumn != null && taskStatusLabel != null)  {
       labelArray = await fetchStatusLabel(selectedProject.key, taskStatusColumn.value, taskStatusLabel.value);
    }
      if(assigneeColumn !== undefined && assigneeColumn.key !== undefined){
        // For deleted columns, it is fetching all items on board, need to raise support ticket
        let query;
        if(labelArray != null){
          query = `query {
            items_page_by_column_values (limit: 100, board_id: ` + selectedProject.key + 
            `, columns: [
            {column_id: "` + assigneeColumn.key + `", column_values: ["` + user.uid.toString() + `"]}
            {column_id: "` + taskStatusColumn.key + `", column_values: [` +labelArray+`]}
            ] ) {
              cursor
              items {
                id
                name
              }
            }
          }
          `;

          
        }else{
        query = `query {
          items_page_by_column_values (limit: 100, board_id: ` + selectedProject.key +`, columns: [{column_id: "` + assigneeColumn.key  + `", column_values: ["` + user.uid.toString() + `"]}] ) {
            cursor
            items {
              id
              name
            }
          }
        }
        `;}
        const tokendata = await monday.get('sessionToken');
        const response = await monday.api(
          query,
          {
            options: { token: tokendata.data },
          }
        ).catch((err) => {
          console.log(err);
          // toastHandler.error(err.error_message);
          sentryUtils.captureMondayException('fetchItems', err, 'TimeEntryFormModel');
        });
        
        if(response && response.data){
        
          // console.log(response.data);
          // console.log(response.data.items_page_by_column_values?.items);
          cursorV = response.data.items_page_by_column_values?.cursor;
          arr = response.data.items_page_by_column_values?.items;
          // till cursor is present, query for this 
          while(cursorV !== null){

            let q = `query {
              next_items_page (cursor: "` + cursorV + `", limit: 100) {
                cursor
                items {
                  id
                  name
                }
              }
            }`

            const res = await monday.api(
              q,
              {
                options: { token: tokendata.data },
              }
            ).catch((err) => {
              console.log(err.message);
              toastHandler.error(err.message);
              sentryUtils.captureMondayException('fetchNextItems', err, 'TimeEntryFormModel');
            });
            console.log("tasks: ", res.data?.next_items_page?.items)
            cursorV = res.data?.next_items_page?.cursor
            arr = [...arr, ...res.data?.next_items_page?.items]
            
          }
          console.log("TaskList", taskList);
          if(arr.length === 0){
            toastHandler.warn("No tasks are assigned to you yet");
          }
          setTaskData(arr)
          setLoading(false)
          setMePersonData(response.data.me);
        } 
      } else {
        toastHandler.error("please contact admin to set assignee column for team");
      }
    }
  }

  const fetchSubItems = async () => {
    // console.log(selectedTask);
    if(!(props.timeSheet && props.timeSheet.subtask)){
      setShowSubtask(false)
      setSelectedSubTask(null)
    }
    
    console.log("use effect sub task");
    if (
      selectedTask != null &&
      selectedTask !== "" &&
      selectedTask !== undefined &&
      selectedTask.key !== undefined
    ) {
      setLoading(true)
      console.log("task key " + selectedTask.key);
      let subitemAssigneeColumn = subitemAssigneeColData[selectedProject.key];
      console.log("subitemAssigneeColumn", subitemAssigneeColumn);
      if(subitemAssigneeColumn){
        let query = `query {
          items (ids: ${selectedTask.key}) {
            subitems {
              id
              name
              column_values(ids: ["${subitemAssigneeColumn.key}"]){
                id
                value
                text
              }
            }
          }
        }`
        const tokendata = await monday.get('sessionToken');
        const response = await monday.api(
          query,
          {
            options: { token: tokendata.data },
          }
        ).catch((err) => {
          console.log(err);
          // toastHandler.error(err.error_message);
          sentryUtils.captureMondayException('fetchSubItems', err, 'TimeEntryFormModel');
        });
        
        if(response && response.data){
          console.log(response.data.items[0].subitems);
          let subtasklist = response.data.items[0].subitems
          console.log("sub TaskList", response.data.items[0].subitems);
          
          const filteredSubTask = subtasklist.filter(entry => {
            if(entry.column_values[0]){
              const columnValue = JSON.parse(entry.column_values[0].value);
              return columnValue?.personsAndTeams.some(person => person.id == user.uid);
            }
            
          });
          console.log('filteredSubTask', filteredSubTask);

          // if(filteredSubTask.length === 0){
          //   toastHandler.warn("No sub tasks are assigned to you yet");
          // }
          setAllSubTaskData(subtasklist)
          setSubTaskData(filteredSubTask)
          setLoading(false)
        } 
      // } else {
      //   toastHandler.error("please contact admin to set subitem assignee column for team");
      } else {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchItems();
  }, [selectedProject]);

  useEffect(() => {
    selectProjectDropDown();
  }, [mePersonData]);

  useEffect(() => {
    selectTaskDropDown();
  }, [taskData]);

  useEffect(() => {
    fetchSubItems();
  }, [selectedTask]);

  useEffect(() => {
    selectSubTaskDropDown();
  }, [subTaskData]);


  function selectTaskDropDown() {
    let task = [];
    console.log(taskData);
    if (taskData != undefined) {
      taskData.forEach((item, index) => {
        // console.log(item);
        task.push({
          label: item.name,
          value: item.name,
          key: item.id,
        });
      });
      setTaskDropDownOptions(task);
    }
  }

  function selectSubTaskDropDown() {
    let subTask = [];
    console.log(subTaskData);
    if (subTaskData != undefined) {
      subTaskData.forEach((item, index) => {
        // console.log(item);
        subTask.push({
          label: item.name,
          value: item.name,
          key: item.id,
        });
      });
      setSubTaskDropDownOptions(subTask);
      if(allSubTaskData.length > 0){
        setShowSubtask(true)
        if(subTask.length === 0){
          setSubtaskPlaceholder("Subtasks not assigned yet")
        }
      }
    }
  }

  const checkEntry = async () => {
    const accountId = localStorage.getItem("accountId");
    var formatedDate = new Date(moment(logDate).format());
    var dateToCheck = moment(logDate).format('yyyy-MM-DD');
    //check in redux
    console.log('userTimesheet', userTimesheet)
    let obj = {
      'accountId': accountId,
      'deleted': false,
      'project': { 'key': selectedProject.key },
      'task': { 'key': selectedTask.key },
      'subtask': {'key': selectedSubTask? selectedSubTask.key : null},
      'userId': user.uid,
      'logDate': dateToCheck,
    }

    if (_.find(userTimesheet, obj)) {
      toastHandler.info("Record for selected task & date is already exists.");
      props.onCancel();
    } else {
      const timeEntryRef = collection(firestore, "timeEntry");

      const startOfDay = new Date(formatedDate);
      startOfDay.setHours(0, 0, 0, 0);

      const endOfDay = new Date(formatedDate);
      endOfDay.setHours(23, 59, 59, 999);

      // Convert to Firestore Timestamps
      const startTimestamp = Timestamp.fromDate(startOfDay);
      const endTimestamp = Timestamp.fromDate(endOfDay);

      let q = query(
        timeEntryRef,
        and(
          where("accountId", "==", localStorage.getItem("accountId")),
          where("deleted", "==", false),
          where("project.key", "==", selectedProject.key),
          where("task.key", "==", selectedTask.key),
          where("subtask", "==", null),
          where('userId', '==', user.uid),
          // where('logDate', "==", formatedDate),
          where('logDate', ">=", startTimestamp),
          where('logDate', "<=", endTimestamp),
        )
      );
      if(selectedSubTask && selectedSubTask.key != undefined){
        q = query(
          timeEntryRef,
          and(
            where("accountId", "==", localStorage.getItem("accountId")),
            where("deleted", "==", false),
            where("project.key", "==", selectedProject.key),
            where("task.key", "==", selectedTask.key),
            where("subtask.key", "==", selectedSubTask.key),
            where('userId', '==', user.uid),
            // where('logDate', "==", formatedDate),
            where('logDate', ">=", startTimestamp),
            where('logDate', "<=", endTimestamp),
          )
        );
      }
      const timeEntrySnapshot = await getDocs(q);
      var fetchUserLogData = timeEntrySnapshot.docs.map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data;
      });
      if (fetchUserLogData.length > 0) {
        console.log("Inside TimeEntryFormModel fetchUserLogData");
        console.log("Document data:", fetchUserLogData);
        console.log(fetchUserLogData[0]);
        const existingRecord = fetchUserLogData[0];
        if (existingRecord.approvalStatus == "Approved") {
          toastHandler.info("record for selected task already exists & approved");
          setApproved(true);
        } else {
          toastHandler.info("record for selected task already exists, plz update record");
        }
        setAlreadyExists(true);
        setExistingRecordId(existingRecord.id)
        setBillable(existingRecord.billable);
        setLogTime(existingRecord.logHours);
        setDescription(existingRecord.description);
        form.setFieldsValue({
          logTime: moment(existingRecord.logHours, "HH:mm"),
          description: existingRecord.description,
        });
      
      } else {
        // then add a new document
        console.log("No such document!");
        firestore
          .collection("timeEntry")
          .add({
            // workSpace: selectedWorkSpace,
            project: selectedProject,
            task: selectedTask,
            subtask: selectedSubTask,
            description: description,
            logDate: formatedDate,
            logHours: logTime,
            billable: billable ? true : false,
            accountId: accountId,
            userId: user.uid,
            userName: user.displayName,
            deleted: false,
            approvalStatus: "Pending",
            invoiceStatus: "Pending",
            createdAt: new Date(),
            updatedAt: new Date(),
            reviewerComments: [],
            approvers: approversData[selectedProject.key]
          })
          .then((docRef) => {
            toastHandler.info("Added successfully");
            console.log("Added successfully");
            logEvent(analytics, 'time_entry_added', { "account_id": localStorage.getItem("accountId") });
            props.resetPage()
          })
          .catch((error) => {
            toastHandler.error(error);
            sentryUtils.captureFirestoreException('checkEntry', error, 'TimeEntryFormModel');
          });
        props.onCancel();
      }
    }
  }


  const onFinish = (values) => {
    console.log("allSubTaskData", allSubTaskData);
    console.log("subTaskData", subTaskData);
    console.log("subTaskData.length", subTaskData.length);
    if(allSubTaskData.length > 0 && subTaskData !== undefined && subTaskData.length === 0){
        toastHandler.error({"message": "No sub-items are assigned to you yet for selected task"})
    } else {
      setIsSubmitted(true)
      if (props.timeSheet) {
        if (props.timeSheet.currentForm == "timeSheet") {
          firestore
            .collection("timeEntry")
            .doc(props.timeSheet.id)
            .update({
              // workSpace:selectedWorkSpace,
              // project: selectedProject,
              // task: selectedTask,
              // subtask: selectedSubTask,
              description: description,
              logDate: new Date(moment(logDate).format()),
              logHours: logTime,
              billable: billable,
              updatedAt: new Date(),
            })
            .then((docRef) => {
              toastHandler.info("Updated successfully");
              logEvent(analytics, 'time_entry_updated', { "account_id": localStorage.getItem("accountId") });
              // console.log("updated");
            })
            .catch((error) => {
              setIsSubmitted(false)
              console.log("error in timesheet update");
              toastHandler.error(error);
              sentryUtils.captureFirestoreException('updateEntry', error, 'TimeEntryFormModel');
            });
        }
        else if (props.timeSheet.currentForm == "reSubmit") {
          console.log(props.timeSheet.id)
          if (comment) {
            var approvalStatus = "Resubmitted"
            var formatedDate = moment(new Date()).format(constants.dateTime.DATE);

            console.log(approvalStatus, user.uid, user.displayName, comment, formatedDate)

            firestore
              .collection("timeEntry")
              .doc(props.timeSheet.id)
              .update({
                approvalStatus: approvalStatus,
                reviewerComments: firebase.firestore.FieldValue.arrayUnion({
                  userId: user.uid,
                  userName: user.displayName,
                  approvalStatus: approvalStatus,
                  comment: comment,
                  logTime: new Date(),
                }),
                logHours: logTime,
                description: description,
                updatedAt: new Date(),
                billable: billable,
              })
              .then((docRef) => {
                toastHandler.success("Resubmitted successfully");
                console.log("Resubmitted successfully");
                logEvent(analytics, 'time_entry_resubmitted', { "account_id": localStorage.getItem("accountId") });
              })
              .catch((error) => {
                setIsSubmitted(false)
                toastHandler.error(error);
                sentryUtils.captureFirestoreException('resubmitEntry', error, 'TimeEntryFormModel');
              });
            props.resetPage()
            // console.log(updatedData);

            // const resData = httpsCallable(functions, "updateApproval");
            // resData(updatedData).then((result) => {
            //   // Read result of the Cloud Function.
            //   /** @type {any} */
            //   const data = result.data;
            //   console.log(data);
            // });

            // setTimeout(() => {
            //     setLoading(false);
            //     setOpen(false);
            // }, 3000);
          }

        }
        props.onCancel();
      }
      else if (alreadyExists) {
        console.log("update record that already exists");
        firestore
          .collection("timeEntry")
          .doc(existingRecordId)
          .update({
            description: description,
            logHours: logTime,
            billable: billable,
            updatedAt: new Date(),
          })
          .then((docRef) => {
            toastHandler.info("Updated successfully");
            logEvent(analytics, 'time_entry_updated', { "account_id": localStorage.getItem("accountId") });
            props.resetPage()
            console.log("updated");
          })
          .catch((error) => {
            setIsSubmitted(false)
            console.log("error in timesheet update");
            toastHandler.error(error);
            sentryUtils.captureFirestoreException('updateAlreadyExistsEntry', error, 'TimeEntryFormModel');
          });
        props.onCancel();
      }
      else {
        console.log("in check");
        checkEntry();
      }

      //window.location.reload();
      //props.onCancel();
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function disabledTime() {
    return {
      disabledHours: () => [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
    };
  }

  useEffect(() => {
    if (props.timeSheet?.id !== undefined) {
      form.setFieldsValue({
        project: selectedProject,
        task: selectedTask,
        subtask: selectedSubTask,
        logTime: moment(logTime, "HH:mm"),
        date: moment(logDate),
        billable: billable,
        description: description,
        comment: comment,
      })
    }
  }, [])
  useEffect(() => {
    // Update loading state based on props.timeSheet
    setLoading(false);
  }, [props.timeSheet]);

  return (
    <Form layout="vertical" form={form} onFinish={onFinish} autoComplete="off">
      <Space wrap id="timeentry_project">
        <Form.Item
          label={'Project'}
          name="project"
          rules={[{ required: true, message: 'Please select project!' }]}
        >
          <Select
            name="project"
            placeholder="Select project"
            disabled={props.timeSheet || alreadyExists}
            style={styles.elementWidth}
            showSearch
            filterOption={(inputValue, option) =>
              option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }
            value={selectedProject}
            options={projectDropDownOptions}
            defaultValue={selectedProject}
            onChange={handleProjectChange}
          />
        </Form.Item>
      </Space>
      <Space wrap id="timeentry_task">
        <Form.Item
          label={'Task'}
          name="task"
          rules={[{ required: true, message: 'Please select task!' }]}
        >
          <Select
            name="task"
            placeholder="Select task"
            loading={loading}
            disabled={props.timeSheet || alreadyExists}
            showSearch
            filterOption={(inputValue, option) =>
              option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }
            style={styles.elementWidth}
            value={selectedTask}
            options={taskDropDownOptions}
            defaultValue={selectedTask}
            onChange={handleTaskChange}
          />
        </Form.Item>
      </Space>
      {
        showSubtask && (
          <Space wrap id="timeentry_subtask">
            <Form.Item
              label={'SubTask'}
              name="subtask"
              rules={[allSubTaskData.length > 0 ? { required: true, message: 'Please select subtask!' }: null]}
            >
              <Select
                name="subtask"
                placeholder={subtaskPlaceholder}
                loading={loading}
                disabled={props.timeSheet || alreadyExists}
                showSearch
                filterOption={(inputValue, option) =>
                  option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                }
                style={styles.elementWidth}
                value={selectedSubTask}
                options={subTaskDropDownOptions}
                defaultValue={selectedSubTask}
                onChange={handleSubTaskChange}
              />
            </Form.Item>
          </Space>
        )
      }
      <Space wrap > 
        <div id="timeentry_date">    
          <Form.Item
            label={'Date'}
            name="date"
            rules={[{ required: true, message: 'Please select date!' }]}
          >
            <DatePicker
              name="date"
              style={{ width: 220 }}
              value={moment(logDate)}
              allowClear={false}
              format="yyyy/MM/DD"
              onChange={(date, dateString) => setLogDate(dateString)}
              disabledDate={(current) => current.isAfter(moment())}
              disabled={props.timeSheet || alreadyExists}
            />
          </Form.Item>
        </div>   
        <div id="timeentry_logtime">
          <Form.Item
            label={'Log Time'}
            name="logTime"
            rules={[{ required: true, message: 'Please enter log time!' },
              { validator: validateTime}]}
          >
            <TimePicker popupClassName='noFooterTimePick'
              name="logTime"
              style={{ width: 220 }}
              value={moment(logTime, "HH:mm")}
              format="HH:mm"
              placeholder="_:_"
              minuteStep={30}
              showSecond={false}
              showNow={false}
              disabledTime={disabledTime}
              onSelect={(value) => {
                setLogTime(value.format("HH:mm"));
                form.setFieldValue('logTime',moment(value, "HH:mm"))
              }}
            
              disabled={approved}
            />
          </Form.Item>
        </div>
      </Space>
      <Space wrap id="timeentry_description">
        <Form.Item
          label={'Description'}
          name="description"
          rules={[{ required: true, message: 'Please enter description!' }]}
        >
          <TextArea
            name="description"
            placeholder="Enter description..."
            value={description}
            rows={4}
            onChange={handleDescription}
            style={styles.elementWidth}
            disabled={approved}
          />
        </Form.Item>
      </Space>
      {props?.timeSheet?.currentForm == "reSubmit" && (
        <Space wrap>
          <Form.Item
            label={'Comment'}
            name="comment"
            rules={[{ required: true, message: 'Please enter comment!' }]}
          >
            <TextArea
              name="comment"
              placeholder="Enter comment..."
              value={comment}
              rows={4}
              onChange={handleComment}
              style={styles.elementWidth}
            />
          </Form.Item>
        </Space>
      )}
      <Space wrap id="timeentry_billable">
        <Form.Item
          label={'Billable'}
          name="billable"
          initialValue={true}
        // rules={[{ required: true, message: 'Please select billable!' }]}
        >
          <div className="switch-billable">
            <Switch checked={billable} onChange={handleBill} disabled={approved} />
          </div>
        </Form.Item>
      </Space>
      <Form.Item shouldUpdate>
        {() => (<Button id="timeentry_create" type="primary"  disabled={approved || (isSubmitted && !alreadyExists)} htmlType="submit" 
        style={{ float: 'right' }}> {(props.timeSheet || alreadyExists) ? (props.timeSheet?.currentForm == "reSubmit") ? 'Resubmit' : 'Update' : 'Create'} </Button>)}
      </Form.Item>
    </Form >
  );
}
const styles = {
  elementWidth: {
    width: 450
  },
}

export default TimeEntryFormModel;

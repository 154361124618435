import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux'
import { Tabs } from 'antd';
import CustomReport from '../reports/customReport';
import TimeSheetTableView from '../TimeSheetTableView/TimeSheetTableView';
import Dashboard from '../Charts/Dashboard';
import AllSummaries from './AllSummaries';
import { constants } from '../../constants';
import Analysis from '../Analysis/Analysis';
import Workload from '../Analysis/Workload';
import firestore from "../../firebase";
import { collection, query, where, or, and, onSnapshot } from "firebase/firestore";

const { TabPane } = Tabs;

const TabComponent = () => {
  let loggedInUser = useSelector(state => state.userSlice.user)
  const [selectedTab, setSelectedTab] = useState(1); 
  const [accountFeatureFlag, setAccountFeatureFlag] = useState({
    Workload: false,
    Analysis: false,
  });
  const [masterFeatureFlag, setMasterFeatureFlag] = useState({
    Workload: false,
    Analysis: false,
  });
  const [error, setError] = useState(null);


  const handleTabClick = (tab) => {    
    setSelectedTab(tab);
  };
  const accountId = localStorage.getItem('accountId');
  useEffect(() => {

    if (accountId) {
      const accRef = collection(firestore, 'mondayAccounts');
      const masterFeaturesRef = collection(firestore, 'masterFeatures');

      const accountQuery = query(
        accRef,
        where('active', '==', true),
        where('accountId', '==', accountId)
      );
      const masterFeaturesQuery = query(masterFeaturesRef);

      const accountFeature = onSnapshot(
        accountQuery,
        (snapshot) => {
          if (!snapshot.empty) {
            const accountDoc = snapshot.docs[0].data();
            const accountFeature = accountDoc.featureFlag || {};
            setAccountFeatureFlag({
              Workload: accountFeature.Workload || false,
              Analysis: accountFeature.Analysis || false,
            });
          }
        },
        (err) => {
          setError(err.message);
        }
      );

    const masterFeature = onSnapshot(
        masterFeaturesQuery,
        (snapshot) => {
          if (!snapshot.empty) {
            snapshot.docs.forEach((doc) => {
              const featureFlag = doc.data().featureFlag || {};
              console.log("featureFlag",featureFlag)
              setMasterFeatureFlag({
                Workload: featureFlag.Workload || false,
                Analysis: featureFlag.Analysis || false,
              });
            });
          }
        },
        (err) => {
          setError(err.message);
        }
      );

      return () => {
        accountFeature();
        masterFeature();
      };
    }
  }, []);
  
  const showWorkloadTab = accountFeatureFlag.Workload && masterFeatureFlag.Workload;
  const showAnalysisTab = accountFeatureFlag.Analysis && masterFeatureFlag.Analysis;

  return (
    <Tabs defaultActiveKey="1" tabBarStyle={{ marginLeft: '20px', marginBottom: '5px' }} onTabClick={handleTabClick} >
      {/* <TabPane tab="Dashboard" key="1" >
        <TimeSheetTableView />
      </TabPane> */}

      <TabPane tab="Dashboard" key="1" >
        <Dashboard/>
      </TabPane>

      {loggedInUser && loggedInUser.userRole !== constants.usersRole.MEMBER && (<TabPane tab="Summary" key="2">
        <AllSummaries selectedTab={selectedTab} />        
      </TabPane>)}     

      {loggedInUser && loggedInUser.userRole !== constants.usersRole.MEMBER && (<TabPane tab="Reports" key="3">
        <CustomReport />
      </TabPane>)}

      {loggedInUser && loggedInUser.userRole !== constants.usersRole.MEMBER && showAnalysisTab && (<TabPane tab="Analysis" key="4">
        <Analysis />
      </TabPane>)}

      {loggedInUser && loggedInUser.userRole !== constants.usersRole.MEMBER && showWorkloadTab && (<TabPane tab="Workload" key="5">
        <Workload />
      </TabPane>)}

    </Tabs>
  );
};

export default TabComponent;

import React from "react";
import moment from "moment";
import { DatePicker } from "antd";

function DateRange({ updateDateRange, dateFormat }) {
  const { RangePicker } = DatePicker;

  return (
    <RangePicker
      onChange={updateDateRange}
      defaultValue={[
        moment(moment().startOf("month").format(dateFormat)),
        moment(moment().format(dateFormat)),
      ]}
      disabledDate={(current) => {
        let presentDate = moment().format(dateFormat);
        return current > moment(presentDate, dateFormat);
      }}
      format={dateFormat}
      allowClear={false}
    />
  );
}

export default DateRange;

import _ from 'lodash';
import React, { useState, useEffect, useCallback } from "react";
import { Space, Table, Button, Typography,Spin } from "antd";
import toastHandler from "../../firebaseService/toastHandler";
import {FaCog} from "react-icons/fa";
import "firebase/compat/auth";
import { useSelector } from 'react-redux'
import firebase from "firebase/compat/app";
import reports from "../../firebaseService/reportsFunctions";
import ExportUserSummary from "../Common/ExportUserSummary";
import moment from "moment";
import { Link } from 'react-router-dom';
import { constants } from "../../constants";
import {
  FaPollH
} from "react-icons/fa";
import BoardInfo from "../BoardInfo";
import { DatePicker, Popconfirm } from "antd";
import settingFunctions from "../../firebaseService/settingFunctions";
import "./antd.css";
import fetchMondayAccount from '../../utils/commonUtils'
import sentryUtils from '../../utils/sentryUtils';
import { analytics } from '../../firebase';
import { logEvent } from '@firebase/analytics';

const { Text } = Typography;
function getTotalDaysInMonth(year, month) {
  // Create a Date object set to the first day of the next month
  const nextMonth = new Date(year, month, 1);

  // Subtract one day to get the last day of the current month
  nextMonth.setDate(nextMonth.getDate() - 1);
  //No need of code
  //for feb u can check directly by year
  // if(month==="02")
  // {
  //   console.log("in month2")
  //   let chkLeapYear
  //   chkLeapYear = year/4
  //   if(chkLeapYear === 0)
  //   {
  //     nextMonth.setDate(28)
  //   }
  //   else
  //   {
  //     console.log("in month",nextMonth)
  //     nextMonth.setDate(29)
  //     console.log("in after month",nextMonth)
  //   }
  // }
  // console.log("nextMonth.getDate()",nextMonth.getDate())
  // Get the day of the month, which is the total number of days in the month
  return nextMonth.getDate();
}
export default function UserSummary(props) {
  const {callSummary} = props;
  const user = firebase.auth().currentUser;
  const [loading, setLoading] = useState(true);
  const [month, setMonth] = useState(moment().format('YYYY-MM'));
  const [monthName, setMonthName] = useState('');
  const [monthDays, setMonthDays] = useState(0);
  const [projectList, setProjectList] = useState([]);
  const [projectSettings, setProjectSettings] = useState({});
  const [userList, setUserList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [rowKeys, setRowKeys] = useState([]);
  const [orignalData, setOrignalData] = useState([]);
  const [uniqueKeySet, setUniqueKeySet] = useState([]);  
  const [usersSettings, setUsersSetting] = useState({});  
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(true);
  const [type, setType] = useState('');  
  let loggedInUser = useSelector(state => state.userSlice.user)
  let [mondayBoardSync,setMondayBoardSync] = useState(false)
  const [syncBtnLoading, setSyncBtnLoading] = useState(false);
  
  const disabledDate = (current) => {
    return current && current > moment().endOf('month');
};
  const handleCancel = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setMondayBoardSync(true);
    getAccountInfo();
  }

  const db = firebase.firestore();

  const handleProjectSummary = () => {    
    callSummary("Summary");
  };

  const getAccountInfo = async () => {
    let result = await fetchMondayAccount()
    let userInfo = _.get(result, 'usersSummarySettings') || null;
    if (userInfo) {
      setUsersSetting(userInfo)
    } else if(mondayBoardSync) {
      toastHandler.info('Please update user setting from "Setting" icon.')
    }
    setMondayBoardSync(false);
  }

  const openSettingForm = (type) => {
    console.log("inside icon click", type)
    setOpen(true);    
    getAccountInfo();
    setType('users')
    setTitle('Users summary settings')
  }

  const saveSetting = async (values) => {
    try {

      let db = firebase.firestore();
      let msg = 'Setting saved';
      msg = 'Users settings updated successfully.'
      
      db.collection("mondayAccounts").where("accountId", "==", localStorage.getItem("accountId")).limit(1).get().then(query => {
        const account = query.docs[0];
        if (account) {
          let tmp = account.data();
          tmp.usersSummarySettings = { workspace: values.workspace, board: values.project }
          // console.log(values.project.key)
          let data = {boardId : values.project.key}
          console.log('data',data)
          settingFunctions.updateUserSetting(data)
          setUsersSetting(tmp.usersSummarySettings)
          account.ref.update(tmp);
          toastHandler.success(msg);
        } else {
          toastHandler.info('Account not available.')
        }
        setOpen(false)
      }).catch((error) => { 
        toastHandler.error(error);
        sentryUtils.captureFirestoreException('saveSetting', error, 'UserSummaryIndex');
      });

    } catch (error) {
      toastHandler.error('Error while updating settings.')
    }
  }
  
  useEffect(() => {
    getAccountInfo();
  }, [mondayBoardSync])

  const days = Array.from({ length: monthDays}, (_, index) => {
    const dayNumber = index + 1;
    return dayNumber < 10 ? `0${dayNumber}` : dayNumber.toString();
  });
  const formatSummaryData = (summaryData) => {
    let data = [];
    summaryData.forEach((element, index) => {

      let params = {};
      for (let i = 0; i < element.sumArray.length; i++) {
        params = {
          ...params,
          [element.sumArray[i].logDate]: {
            billableTotalHrs: element.sumArray[i].billableTotalHrs==="00:00" ? "__:__":element.sumArray[i].billableTotalHrs,
            nonBillableTotalHrs: element.sumArray[i].nonBillableTotalHrs==="00:00"?"__:__":element.sumArray[i].nonBillableTotalHrs,
          },
        };
      }
      data.push({
        key: parseInt(element.userId),
        userKey: parseInt(element.userId),
        userName: element.userName,
        total: {
          totalBillable: element.totalBillable==="00:00"?"__:__":element.totalBillable,
          totalNonBillable: element.totalNonBillable==="00:00"?"__:__":element.totalNonBillable,
        },
        ...params,
        projectsArray: element.projectsArray,
      });
    });
    return data;
  };

  const formatProjectData = (summaryData, record) => {
    let data = [];
    summaryData.forEach((element, index) => {
      let params = {};
      for (let i = 0; i < element.prjSumArrays.length; i++) {
        params = {
          ...params,
          [element.prjSumArrays[i].logDate]: {
            billableTotalHrs: element.prjSumArrays[i].billableTotalHrs==="00:00" ? "__:__":element.prjSumArrays[i].billableTotalHrs,
            nonBillableTotalHrs: element.prjSumArrays[i].nonBillableTotalHrs==="00:00" ? "__:__":element.prjSumArrays[i].nonBillableTotalHrs,
          },
        };
      }
      data.push({
        key: parseInt(element.projectKey),
        projectKey: parseInt(element.projectKey),
        projectName: element.projectName,
        total: {
          totalBillable: element.totalBillable==="00:00"?"__:__":element.totalBillable,
          totalNonBillable: element.totalNonBillable==="00:00"?"__:__":element.totalNonBillable,
        },
        ...params,
        userName: record.userName,
      });
    });
    return data;
  };
  const syncMondayboard = () => {
    setLoading(true);
    let month_start = month + `-1`;
    let month_end = month + `-${monthDays}`;
    reports.updateMondayUserSummaryBoard(month_start, month_end).then((result) => {
      setLoading(false);
    });
  };
  const syncSummary = () => {
    setLoading(true);
    setSyncBtnLoading(true);
    let month_start = month + `-1`;
    let month_end = month + `-${monthDays}`;
    console.log("month_end",month_start,month_end)
    reports.updateUserSummaryDoc(month_start, month_end).then((result) => {
      setSyncBtnLoading(false);
      setLoading(false);
    });
  };

  const HrsComponent = (props) => {
    let hrs = props.hrs;
    let billable = props.billable;
    let fontColor = props.fontColor; 

    const textStyle = {
      color: fontColor // Apply custom font color
    };
    return (
      <>
        <Text type={billable ? "success" : "danger"} style={textStyle}>{hrs}</Text>
        {/* <span className="hrs">{" Hrs"}</span> */}        
      </>
    );
  };
  const expandedRowRender = (record) => {
    const childColumn = [
      {
        title: "Project Name",
        key: "projectName",
        daftaIndex: "projectName",
        width: 130,
        fixed: "left",
        // filters: projectList,
        onFilter: (value, record) => record.projectName.indexOf(value) > -1,
        sorter: (a, b) => a?.projectName?.localeCompare(b?.projectName),
        render: (_, record) => record.projectName,
      },
      {
        title: "Total",
        colSpan: 2,
        width: 140,
        children: [
          {
            title: "billable",
            dataIndex: "total",
            key: "total",
            //   fixed: "right",
            align: "center",
            width: 70,
            render: (_, record) => {
              return {
                props: {
                  style: { background: "rgb(209 255 209)" },
                },
                children: (
                  <HrsComponent
                    hrs={record.total.totalBillable}
                    billable={true}
                    fontColor="black"
                  />
                ),
              };
            },
          },
          {
            title: "non billable",
            dataIndex: "total",
            key: "total",
            //   fixed: "right",
            align: "center",
            width: 70,
            render: (_, record) => {
              return {
                props: {
                  style: { background: "#fbdcdc" },
                },
                children: (
                  <HrsComponent
                    hrs={record.total.totalNonBillable}
                    billable={false}
                    fontColor="black"
                  />
                ),
              };
            },
          },
        ],
      },
      ...days.map((day) => ({
        title: `${monthName} ${day}`,
        dataIndex: `${day}`,
        colSpan: 2,
        width: 900,
        key: `${day}`,
        children: [
          {
            title: "Billable",
            dataIndex: "billableTotalHrs",
            key: "billableTotalHrs",
            width: 60,
            align: "center",
            render: (_, record) => {
              return (
                <HrsComponent
                  hrs={record[`${day}`].billableTotalHrs}
                  billable={true}
                />
              );
            },
          },
          {
            title: "NonBillable",
            dataIndex: "nonBillableTotalHrs",
            key: "nonBillableTotalHrs",
            width: 80,
            align: "center",
            render: (_, record) => (
              <HrsComponent
                hrs={record[`${day}`].nonBillableTotalHrs}
                billable={false}
              />
            ),
          },
        ],
      })),
    ];
    const childRows = _.get(record, 'projectsArray') || [];
    let projectData = formatProjectData(childRows, record);
    projectData = _.sortBy(projectData, 'projectName')
    return (
      <Table
        className="summary"
        // loading={loading}
        columns={childColumn}
        dataSource={projectData}
        rowKey={"projectKey"}
        scroll={{ x: 6000 }}
        size={'small'}
        bordered
        pagination={false}
      />
    );
  };

  const expandedRow = {
    expandedRowRender,
    expandRowByClick: true, // Allow expanding rows by clicking anywhere on the row
    rowKeys,
  };
  const columns = [
    {
      title: "UserName",
      key: "userName",
      dataIndex: "userName",
      width: 150,
      fixed: "left",
      filters: userList,
      onFilter: (value, record) => record.userName.indexOf(value) > -1,
      sorter: (a, b) => a?.userName?.localeCompare(b?.userName),
      render: (_, record) => record.userName,
    },
    {
      title: "Total",
      colSpan: 2,
      children: [
        {
          title: "Billable",
          dataIndex: "total",
          key: "total",
          //   fixed: "right",
          align: "center",
          width: 80,
          render: (_, record) => {
            return {
              props: {
                style: { background: "rgb(209 255 209)" },
              },
              children: (
                <HrsComponent
                  hrs={record.total.totalBillable}
                  billable={true}
                  fontColor="black"
                />
              ),
            };
          },
        },
        {
          title: "NonBillable",
          dataIndex: "total",
          key: "total",
          //   fixed: "right",
          align: "center",
          width: 80,
          render: (_, record) => {
            return {
              props: {
                style: { background: "#fbdcdc" },
              },
              children: (
                <HrsComponent
                  hrs={record.total.totalNonBillable}
                  billable={false}
                  fontColor="black"
                />
              ),
            };
          },
        },
      ],
    },
    ...days.map((day) => ({
      title: `${monthName} ${day}`,
      dataIndex: `${day}`,
      colSpan: 2,
      key: `${day}`,
      children: [
        {
          title: "Billable",
          dataIndex: "billableTotalHrs",
          key: "billableTotalHrs",
          align: "center",
          width: 80,
          render: (_, record) => {
            return (
              <HrsComponent
                hrs={record[`${day}`].billableTotalHrs}
                billable={true}
              />
            );
          },
        },
        {
          title: "NonBillable",
          dataIndex: "nonBillableTotalHrs",
          key: "nonBillableTotalHrs",
          align: "center",
          width: 80,
          render: (_, record) => (
            <HrsComponent
              hrs={record[`${day}`].nonBillableTotalHrs}
              billable={false}
            />
          ),
        },
      ],
    })),
    
  ];


  const onChange = (date, dateString) => {
    setMonth(dateString);
  };

  const keyOrder = ['userName', 'projectName', 'projectKey', 'totalBillable', 'totalNonBillable'];
  let dayKeyArray = [];
  useEffect(() => {
    let data = [];
    let formatedData = [];
    dataSource.forEach((element, index) => {
      const childRows = _.get(element, 'projectsArray') || [];
      formatedData.push([{ ...element, projectName: 'All Project Summary' }])
      formatedData.push(formatProjectData(childRows, element));
    });
    formatedData.forEach((element, index) => {
      let transformedData = {};
      element.forEach((item, index) => {
        let jsonKeys = {};
        for (const dayKey in item) {
          if (item.hasOwnProperty(dayKey)) {
            const dayData = item[dayKey];
            if (typeof (dayData) === 'object') {

              if (dayKey == 'total') {
                jsonKeys['totalBillable'] = dayData.totalBillable;
                jsonKeys['totalNonBillable'] = dayData.totalNonBillable;
              } else {
                if (dayKey != 'projectsArray') {
                  const billableKey = `${monthName}-${dayKey}_billable`;
                  const nonBillableKey = `${monthName}-${dayKey}_nonbillable`;
                  dayKeyArray.push(billableKey);
                  dayKeyArray.push(nonBillableKey);
                  jsonKeys[billableKey] = dayData.billableTotalHrs;
                  jsonKeys[nonBillableKey] = dayData.nonBillableTotalHrs;
                }
              }
            } else {
              jsonKeys[dayKey] = dayData;
            }
          }
          transformedData = { ...transformedData, ...jsonKeys }
        }
        data.push(transformedData);
      });
    });
    let uniqueSet = new Set(dayKeyArray);
    let newArray = Array.from(uniqueSet);
    newArray = _.sortBy(newArray)
    let finalKeyArray = keyOrder.concat(newArray)
    setUniqueKeySet(finalKeyArray);
    const rearrangedData = data.map((obj) => {
      const newObj = {};
      finalKeyArray.forEach((key) => {
        newObj[key] = obj[key];
      });
      delete newObj['Aug-projectsArray_billable']
      delete newObj['Aug-projectsArray_nonbillable']

      return newObj;
    });
    setExportData(rearrangedData);
  }, [dataSource]);

  useEffect(() => {
    if (month != null && month != '') {
      const [year, months] = month?.split('-');
      const date = new Date(year, months - 1, 1);
      const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      setMonthName(monthNames[date.getMonth()])
      let totalDays = getTotalDaysInMonth(year, months)
      setMonthDays(totalDays)
    }
  }, [month])

  useEffect(() => {
    if (month != null && month != '') {
      const summaryRef = db.collection("usersSummary");
      let monthString = month + `-${monthDays}`;
      let summaryDocSnapshotListner = summaryRef
        .where("accountId", "==", localStorage.getItem("accountId"))
        .where("deleted", "==", false)
        .where("month", "==", monthString)
        .onSnapshot((docs) => {
          if (docs.size >= 1) {
            let summary = [];
            docs.forEach((doc) => {
              summary = doc.data();
            });
            let summaryData = summary["summaryArray"];
            let updatedAt = summary["updatedAt"];
            if ((updatedAt && moment().diff(moment(updatedAt.toDate()), 'minutes') >= 5)|| updatedAt===undefined) {
              console.log('Sync triggered due to outdated timestamp');
              syncSummary(); // Automatically sync if timestamp is older than 5 minutes
            }
            if (summaryData.length > 0) {
              let data = formatSummaryData(summaryData);
              data = _.sortBy(data, 'userName')
              setDataSource(data);
              setOrignalData(summaryData);
            } else {
              setDataSource([]);
              toastHandler.info(`Records not available for the month ${month}`);
            }
          } else {
            // toastHandler.info(
            //   `Data is not synchronized for the month  ${month} .Please click on sync button.`
            // );
            // setDataSource([]);
            syncSummary();
          }
          setLoading(false);
        });
      return () => {
        summaryDocSnapshotListner();
        console.log("summary component unmounted");
      };
    }
  }, [month, monthDays]);



  useEffect(() => {
    const expandedRowKeys = dataSource.map((record) => record.key);
    setRowKeys(expandedRowKeys);

    const userNames = dataSource.map(record => ({ text: record.userName, value: record.userName }));
    setUserList(userNames);

    // const projectNames = dataSource.flatMap(records => {
    //   return records.projectsArray.map(project => ({ text: project.projectName, value: project.projectName, id: parseInt(project.projectKey) }));
    // });

    // const seenIds = {};
    // const projectFilteredData = projectNames.filter(item => {
    //   if (!seenIds[item.id]) {
    //     seenIds[item.id] = true;
    //     return true;
    //   }
    //   return false;
    // });
    // setProjectList(projectFilteredData);

  }, [dataSource]);

  useEffect(() =>{
    logEvent(analytics,"user_summary" , { "account_id": localStorage.getItem("accountId") });
  }, [])


  return (
    <div class="card mb-4">
      <div class="card-header py-3">
        <div className="float-left custom-datepicker">
          <DatePicker
            defaultValue={month != '' ? moment(month) : moment()}
            style={{ width: '50%' }}
            onChange={onChange}
            picker="month"
            allowClear={false}
            disabledDate={disabledDate}
          />
        </div>
        <div className="float-right">
          <Space direction="horizantal"> 
          {/* {(loggedInUser?.userRole == constants.usersRole.ADMIN || loggedInUser?.userRole == constants.usersRole.SUPER_ADMIN) && (           
            <Button
                type="text"
                onClick={() => openSettingForm('users')}
                icon={<FaCog />}  
                className="settings-icon"              
              />  
          )}             */}
            <Button style={{ color: '#1B2F78' }} icon={<FaPollH />} onClick={handleProjectSummary}>
                &nbsp; Project Summary 
            </Button>       
            {/* {(loggedInUser?.userRole == constants.usersRole.ADMIN || loggedInUser?.userRole == constants.usersRole.SUPER_ADMIN) && (       
            <Popconfirm
              title={`Are you confirm to save summary within - ${usersSettings?.board?.label} board?`}
              okText="Yes"
              cancelText="No"
              onConfirm={() => syncMondayboard()}
              disabled={!usersSettings || dataSource.length <= 0}
            >
              <Button type="primary"
                disabled={!usersSettings || dataSource.length <= 0}
                onClick={() => getAccountInfo()}>
                <i class="fas fa-sync"></i>
                &nbsp; Sync Monday Board
              </Button>
            </Popconfirm>
            )} */}
            {/* {(loggedInUser?.userRole === constants.usersRole.ADMIN || 
              loggedInUser?.userRole === constants.usersRole.SUPER_ADMIN) && ( 
              usersSettings?.board?.label ? (  
              <Popconfirm
                title={`Are you confirm to save summary within - ${usersSettings?.board?.label} board?`}
                okText="Yes"
                cancelText="No"
                onConfirm={() => syncMondayboard()}
                disabled={!usersSettings || dataSource.length <= 0}
              >
                <Button type="primary" 
                  disabled={!usersSettings || dataSource.length <= 0}>
                  <i className="fas fa-sync"></i>
                  &nbsp; Sync Monday Board
                </Button>
              </Popconfirm>
              ):(<Button type="primary" 
              onClick={() => handleClick()}>
              <i className="fas fa-sync"></i>
              &nbsp; Sync Monday Board
            </Button>)
            )} */}
            <Button type="primary"  onClick={() => syncSummary()} 
            loading={syncBtnLoading}
            disabled={syncBtnLoading}
            >
              <i class="fas fa-sync"></i>
              &nbsp; Sync
            </Button>
            <ExportUserSummary
              uniqueKeySet={uniqueKeySet}
              exportInfo={{
                exportData: exportData,
                from: "userSummary",
              }}
            />
          </Space>
        </div>
      </div>
      
      {loading ? (
        <div className="spin-div" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'70vh'}}>
          <Spin size="large" tip="Loading..." />
        </div>
          ) : (
      <Table
        // loading={loading}
        columns={columns}
        dataSource={dataSource}
        rowKey={"userKey"}
        scroll={{ x: 6000, y: 400 }} //constants.tableHeight()
        expandable={expandedRow}
        bordered
        size={'small'}
      />      
    )}
      {open ? <BoardInfo
        open={open}
        projectSettings={projectSettings}
        title={title}
        type={type}
        handleCancel={handleCancel}
        saveSetting={saveSetting}
        usersSettings={usersSettings}
      /> : null}
      <br />
    </div>    
  );
}
